import React, { useCallback, useState, useMemo } from "react";
import { Button as BtnUikit, Icon } from "uikit";
import { isEqual, omit } from "lodash";

import { Language } from "../../../../../services";
import { Orders } from "../../../../../redux/reducers/Orders";
import { useTypedSelector } from "../../../../../redux/store";
import { baseFilters } from "../../../../../redux/reducers/Orders/Chat/filter";
import { ModalFilter } from "../../../../../redux/reducers/Orders/Chat/filter/interface";

import { Popover, Popup } from "./components";

const ExecutorsFilterPanel: React.FC<ExecutorsFilterPanel.Props> = (props) => {
	const { filter, setFilter, language, onSave, criteria } = props;

	const executors =
		useTypedSelector(
			(state) => state.globalState.executors.data.subscription.basic,
		) || [];

	const executorIds = useMemo(
		() => executors.map((executor) => executor.id),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[executors.length],
	);

	const isFilterActive: boolean = useMemo(() => {
		// const filtersWithoutExecutorIds = omit(baseFilters, ["executorIds"]);
		// const criteriaWithoutExecutorIds = omit(criteria, ["executorIds"]);

		// const filtersWithoutExecutorIds = omit(baseFilters, ["executorIds"]);
		// const criteriaWithoutExecutorIds = omit(criteria, ["executorIds"]);

		const isBaseFilterSelectActive = !isEqual(baseFilters, criteria);

		// const isExecutorIdsEqual = (() => {
		// 	if (!criteria.executorIds || !executorIds) return false;

		// 	const arrayA = [...criteria.executorIds].sort();
		// 	const arrayB = [...executorIds].sort();

		// 	if (arrayA.length !== arrayB.length) return true;

		// 	return !arrayA.every((id, index) => id === arrayB[index]);
		// })();

		return isBaseFilterSelectActive; /* || isExecutorIdsEqual; */
	}, [criteria]);

	const { Button } = BtnUikit;

	const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);

	const popoverOpen = useCallback(() => {
		setOpenAdditionalFilters(true);
	}, []);

	const popoverClose = useCallback(() => {
		setOpenAdditionalFilters(false);
	}, []);

	return (
		<Popup
			open={openAdditionalFilters}
			offset={{ x: -470, y: 40 }}
			styles={{ shadow: "rgba(0,0,0,0.2) 0px 6px 24px 0px" }}
			tracker={
				<Button
					style={
						isFilterActive
							? { backgroundColor: "rgb(109,190,255)" }
							: {}
					}
					variant={isFilterActive ? "primary" : "secondary"}
					icon={<Icon id="filters" size={20} />}
					onClick={popoverOpen}
				/>
			}
		>
			<Popover
				language={language}
				filter={filter}
				setFilter={setFilter}
				onClose={popoverClose}
				onSave={onSave}
				criteria={criteria}
			/>
		</Popup>
	);
};

declare namespace ExecutorsFilterPanel {
	interface Props {
		filter: Orders.Chat.ModalFilter;
		setFilter: React.Dispatch<
			React.SetStateAction<Orders.Chat.ModalFilter>
		>;
		language: Language;
		onSave: (data: Orders.Chat.ModalFilter) => void;
		criteria: ModalFilter;
	}
}

export default ExecutorsFilterPanel;
