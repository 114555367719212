import React, { Dispatch, useCallback, useMemo, useState } from "react";
import { Float, Icon, useInternal, Button } from "uikit";
import { sha1 } from "object-hash";
import { isEqual } from "lodash";

import { useTypedSelector } from "../../../../../../../redux/store";
import { defaultMapWidgetExecutorsFilters } from "../../../../../../../redux/reducers/Orders/Map/filter";
import {
	useCompanyIdsDecoder,
	useTaxiServiceIdsDecoder,
	useCarParkIdsDecoder,
} from "../../../../../../../hooks";
import { Popup } from "../../../../../../common";

import Content from "./components/Content";
// import Button from "./components/Button";

const offset: Float.OffsetCallback = ({ tracker, float }) => {
	if (!tracker || !float) return { x: 0, y: 0 };

	const trackerSize = tracker.getSize();
	const floatSize = float.getSize();

	return {
		x: -Number(floatSize.width) + Number(trackerSize.width),
		y: Number(trackerSize.height) + 8,
	};
};

const Filters: React.FC<Filters.Props> = ({
	value,
	defaultValue,
	onChange,
	onApply,
}) => {
	const activeOrderTab = useTypedSelector(
		(state) => state.ordersPageReducer.ordersType,
	);
	const mapWidgetExecutorFilters = useTypedSelector(
		(state) =>
			state.orders.map.filter.persistent.executors.filtersByOrderTab[
				activeOrderTab
			],
	);

	const allTaxiServiceIds = useTaxiServiceIdsDecoder();
	const allCompanyIds = useCompanyIdsDecoder();
	const allCarParkIds = useCarParkIdsDecoder();

	const taxiServiceIds = useMemo(
		() => allTaxiServiceIds(["all"], ["all"], { isOnlyAccess: true }),
		[allTaxiServiceIds],
	);
	const companyIds = useMemo(
		() => allCompanyIds(["all"], { isOnlyAccess: true }),
		[allCompanyIds],
	);

	const fleetIdsAll = useMemo(() => allCarParkIds(["all"]), [allCarParkIds]);

	const [popupOpen, setPopupOpen] = React.useState(false);

	const [internalValue, setInternalValue] = useState(value);
	const [showModalPropsForExecutorGroup, setShowModalPropsForExecutorGroup] =
		useState<boolean>(false);
	const [showModalPropsForCarPark, setShowModalPropsForCarPark] =
		useState<boolean>(false);

	const isActiveShowMultiModal =
		showModalPropsForExecutorGroup || showModalPropsForCarPark;

	const valueHash = useMemo(() => sha1(value), [value]);
	const defaultValueHash = useMemo(() => sha1(defaultValue), [defaultValue]);

	const buttonActive = useMemo(
		() => popupOpen || valueHash !== defaultValueHash,
		[defaultValueHash, popupOpen, valueHash],
	);

	const internalOnChange = useCallback(
		(value: Filters.Value) => {
			setInternalValue(value);
			onChange?.(value);
		},
		[onChange, setInternalValue],
	);

	const closePopup = useCallback(() => {
		if (!isActiveShowMultiModal) setPopupOpen(false);
	}, [isActiveShowMultiModal]);

	const contentOnReset = useCallback(() => {
		setInternalValue({
			...defaultMapWidgetExecutorsFilters,
			executorGroupIds: [],
		});
	}, [setInternalValue]);

	const contentOnApply = useCallback(() => {
		closePopup();

		onApply(internalValue);
	}, [closePopup, internalValue, onApply]);

	const isFilterActive = useMemo(() => {
		if (
			companyIds?.length !==
				mapWidgetExecutorFilters.companyIds?.length &&
			!isEqual(mapWidgetExecutorFilters.companyIds, ["all"])
		)
			return true;
		if (
			taxiServiceIds?.length !==
				mapWidgetExecutorFilters.taxiServiceIds?.length &&
			!isEqual(mapWidgetExecutorFilters.taxiServiceIds, ["all"])
		)
			return true;
		if (
			fleetIdsAll?.length !== mapWidgetExecutorFilters.fleetIds?.length &&
			!isEqual(mapWidgetExecutorFilters.fleetIds, ["all"])
		)
			return true;
		// if (mapWidgetExecutorFilters.fleetIds?.length > 0) return true;
		if (mapWidgetExecutorFilters.executorGroupIds?.length > 0) return true;
		return false;
	}, [
		companyIds?.length,
		fleetIdsAll?.length,
		mapWidgetExecutorFilters.companyIds,
		mapWidgetExecutorFilters.executorGroupIds?.length,
		mapWidgetExecutorFilters.fleetIds,
		mapWidgetExecutorFilters.taxiServiceIds,
		taxiServiceIds?.length,
	]);

	return (
		<Popup
			open={popupOpen}
			useClickControl
			tracker={
				<Button.Button
					style={
						isFilterActive
							? { backgroundColor: "rgb(109,190,255)" }
							: {}
					}
					variant={isFilterActive ? "primary" : "secondary"}
					// variant="secondary"
					// onClick={buttonActive}
					icon={<Icon id="filters" size={20} />}
				/>
			}
			offset={offset}
			onChangeOpen={(bool) => {
				if (bool) setPopupOpen(bool);
				else if (!isActiveShowMultiModal) setPopupOpen(bool);
			}}
		>
			<Content
				value={internalValue}
				onChange={internalOnChange}
				onReset={contentOnReset}
				onClose={closePopup}
				onApply={contentOnApply}
				setShowModalPropsForExecutorGroup={
					setShowModalPropsForExecutorGroup
				}
				setShowModalPropsForCarPark={setShowModalPropsForCarPark}
			/>
		</Popup>
	);
};

declare namespace Filters {
	type Value = Content.Value;

	interface Props {
		value: Value;

		defaultValue: Value;

		onChange?: Dispatch<Value>;

		onApply: Dispatch<Value>;
	}
}

export default Filters;
