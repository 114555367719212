import { useCallback, useMemo } from "react";

import { CarPark } from "../../services";
import { useTypedSelector } from "../../redux/store";
import {
	useCarParkFilterAccess,
	useTaxiServiceFilterAccess,
} from "../../access";
import { useModelSubscribe } from "..";

function useCarParkIdsDecoder() {
	const { isUserFleetOwner, accessFleetsIdsOnlyActive: accessIds } =
		useCarParkFilterAccess<any>();
	const { accessTaxiServicesIds } = useTaxiServiceFilterAccess<any>();
	const optionsSubscribe: CarPark.SubscribeOptions = useMemo(
		() => ({
			...(accessTaxiServicesIds
				? {
						taxiServiceIds: accessTaxiServicesIds,
				  }
				: {}),
		}),
		[accessTaxiServicesIds],
	);

	const subscribe = useModelSubscribe(optionsSubscribe, CarPark);

	const sub = useMemo(() => {
		const retval = subscribe.models;

		return retval;
	}, [subscribe.models]);

	// const executors =
	// 	useTypedSelector(
	// 		(state) => state.globalState.executors.data.subscription.basic,
	// 	) || [];

	const availableIds = useMemo(() => sub.map((item) => item.id), [sub]);

	const isAllIds = useCallback(
		(
			taxiServices: useCarParkIdsDecoder.EncodedIds,
		): taxiServices is ["all"] => taxiServices[0] === "all",
		[],
	);

	const decoder = useCallback(
		(
			companyIds: useCarParkIdsDecoder.EncodedIds,
			options: useCarParkIdsDecoder.DecoderOptions = {},
		) => {
			const isOnlyAccessIds = isUserFleetOwner ? accessIds : availableIds;
			if (isAllIds(companyIds)) return isOnlyAccessIds;

			return isOnlyAccessIds.filter((companyId) =>
				companyIds.includes(companyId),
			);
		},
		[accessIds, availableIds, isAllIds, isUserFleetOwner],
	);

	return decoder;
}

declare namespace useCarParkIdsDecoder {
	type EncodedIds = number[] | ["all"];

	interface DecoderOptions {
		// isOnlyAccess?: boolean;
	}
}

export default useCarParkIdsDecoder;
