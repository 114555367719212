import { useCallback, useMemo } from "react";
import { get, isArray } from "lodash";

import { useTypedSelector } from "../../redux/store";
import { FleetsToDispatcher } from "../../redux/reducers/account";

const useCarParkFilterAccess = <T>(): useCarParkFilterAccess.Props<T> => {
	const user = useTypedSelector((state) => state.account.user);

	const {
		isUserFleetOwner,
		idUser,
		accessFleets,
		accessFleetsOnlyActive,
		accessFleetsIds,
		accessFleetsIdsOnlyActive,
		accessSetFleetsIdsOnlyActive,
	} = useMemo(() => {
		const isUserFleetOwner =
			Array.isArray(user?.dispatcherToFleets) &&
			(user?.dispatcherToFleets?.length ?? 0) > 0;
		const idUser = user?.id ?? -1;

		const accessFleets: FleetsToDispatcher[] =
			user?.dispatcherToFleets ?? [];

		const accessFleetsOnlyActive: FleetsToDispatcher[] =
			accessFleets.filter((item) => item.fleet?.active);

		const accessFleetsIds: number[] = accessFleets.flatMap((item) =>
			item.fleet?.id ? [item.fleet.id] : [],
		);

		const accessFleetsIdsOnlyActive: number[] =
			accessFleetsOnlyActive.flatMap((item) =>
				item.fleet?.id ? [item.fleet.id] : [],
			);

		const accessSetFleetsIdsOnlyActive: Set<number> = new Set(
			accessFleetsIdsOnlyActive,
		);

		return {
			accessFleets,
			accessFleetsOnlyActive,
			accessFleetsIds,
			accessFleetsIdsOnlyActive,
			isUserFleetOwner,
			idUser,
			accessSetFleetsIdsOnlyActive,
		};
	}, [user]);

	// console.group("Console_Group_DP0");
	// console.log(`accessFleets`, accessFleets);
	// console.log(`accessFleetsOnlyActive`, accessFleetsOnlyActive);
	// console.log(`accessFleetsIds`, accessFleetsIds);
	// console.log(`accessFleetsIdsOnlyActive`, accessFleetsIdsOnlyActive);
	// console.log(`isUserFleetOwner `, isUserFleetOwner);

	// console.log(`user`, user);
	// // console.table(mergedActiveFilters);
	// console.groupEnd();

	const subCarParkFilterAccess = useCallback(
		({
			models,
			branchPath,
			branchPath2,
			isUserCreated,
			branchPath3,
			isOrderLogic,
		}: useCarParkFilterAccess.Params<T>): T[] => {
			if (!Array.isArray(models) || typeof branchPath !== "string") {
				return [];
			}

			// // ...(isUserFleetOwner
			// // 	? { carParkIds: accessFleetsIdsOnlyActive }
			// // 	: {}),

			// console.group("Console_Group_DP52");
			// console.log(`models`, models);
			// console.log(`branchPath`, branchPath);
			// console.log(`branchPath2`, branchPath2);
			// console.log(`isUserCreated`, isUserCreated);
			// console.log(`branchPath3`, branchPath3);
			// console.log(`isUserFleetOwner`, isUserFleetOwner);
			// console.log(`isOrderLogic`, isOrderLogic);

			// console.groupEnd();

			// // return models; idUser

			const arrTypeAccessIds = accessSetFleetsIdsOnlyActive;

			const accessModels = isUserFleetOwner
				? models.filter((model) => {
						const branchId = get(model, branchPath, -2);
						const idDispatcher = branchPath3
							? get(model, branchPath3, -2)
							: -2;

						if (isUserCreated && idDispatcher === idUser)
							return true;

						if (isArray(branchId)) {
							if (isOrderLogic && branchId.length === 0) {
								return true;
							}
							if (branchPath2) {
								return branchId.some((item) => {
									const nestedId = get(item, branchPath2, -2);
									return (
										typeof nestedId === "number" &&
										arrTypeAccessIds.has(nestedId)
									);
								});
							}

							return (
								branchId.every(
									(id) => typeof id === "number",
								) &&
								branchId.some((id) => arrTypeAccessIds.has(id))
							);
						}

						return arrTypeAccessIds.has(branchId as number);
				  })
				: models;

			// console.group("Console_Group_DP53");
			// console.log(`models`, models);
			// console.log(`branchPath`, branchPath);
			// console.log(`branchPath2`, branchPath2);
			// console.log(`isUserCreated`, isUserCreated);
			// console.log(`accessModels`, accessModels);
			// console.log(
			// 	`accessSetFleetsIdsOnlyActive`,
			// 	accessSetFleetsIdsOnlyActive,
			// );

			// console.groupEnd();

			return accessModels;
		},
		[accessSetFleetsIdsOnlyActive, idUser, isUserFleetOwner],
	);

	return useMemo(
		() => ({
			subCarParkFilterAccess,
			isUserFleetOwner,
			accessFleetsIdsOnlyActive,
		}),
		[subCarParkFilterAccess, isUserFleetOwner, accessFleetsIdsOnlyActive],
	);
};

declare namespace useCarParkFilterAccess {
	interface Props<T> {
		subCarParkFilterAccess: (params: Params<T>) => T[];
		isUserFleetOwner: boolean;
		accessFleetsIdsOnlyActive: number[];
	}

	export interface Params<T> {
		models: T[];
		branchPath: string;
		branchPath2?: string;
		isUserCreated?: boolean;
		branchPath3?: string;
		isOrderLogic?: boolean;
	}

	interface SmallSubscribeOptions {
		// order?: Role.Order;
		// query?: string;
		// limit?: number;
		// assignableTo?: Role.AssignableTo;
		// taxiServiceIds?: number[];
	}
}

export default useCarParkFilterAccess;
