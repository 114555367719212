/* eslint-disable no-param-reassign */

import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LatLngLiteral } from "leaflet";

import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import { State, OrderTab, DefaultExecutorsFilters } from "./interface";

export type UnifiedReducer<Payload> = CaseReducer<
	State,
	PayloadAction<Payload>
>;

export const defaultMapWidgetExecutorsFilters: DefaultExecutorsFilters = {
	onActiveOrder: false,
	companyIds: ["all"],
	taxiServiceIds: ["all"],
	executorGroupIds: [],
	fleetIds: ["all"],
	statuses: [
		"free",
		"busy",
		"lunch",
		"home",
		"onOrder",
		"ownOrder",
		"closedByOffice",
		"autoClose",
	],
	shifts: ["open", "closed"],
};

const initialState: State = {
	persistent: {
		executors: {
			defaultFilters: defaultMapWidgetExecutorsFilters,
			filtersByOrderTab: {
				closed: defaultMapWidgetExecutorsFilters,
				preliminary: defaultMapWidgetExecutorsFilters,
				all: defaultMapWidgetExecutorsFilters,
				executable: defaultMapWidgetExecutorsFilters,
				live: defaultMapWidgetExecutorsFilters,
			},
		},
	},
	inconstant: {
		focus: {
			center: undefined,
			zoom: 11,
		},
	},
};

const setMapWidgetOrderTabExecutorFilters: ReduxReducer<
	State,
	{
		tab: OrderTab;
		filters: DefaultExecutorsFilters;
	}
> = (state, action) => {
	state.persistent.executors.filtersByOrderTab[action.payload.tab] =
		action.payload.filters;
};

const setMapWidgetCenter: ReduxReducer<State, LatLngLiteral | undefined> = (
	state,
	action,
) => {
	state.inconstant.focus.center = action.payload;
};

const setMapWidgetZoom: ReduxReducer<State, number> = (state, action) => {
	state.inconstant.focus.zoom = action.payload;
};

const setAll: ReduxReducer<State, State | undefined> = (_, action) =>
	action.payload ? action.payload : initialState;

const ordersMapFilter = createSlice({
	name: Orders.ConstantOrdersSlice.MAP_FILTERS,
	initialState,
	reducers: {
		setMapWidgetOrderTabExecutorFilters,
		setMapWidgetCenter,
		setMapWidgetZoom,
		setAll,
	},
});

export const orderMapFiltersAction = ordersMapFilter.actions;
export const orderMapFiltersReducer = ordersMapFilter.reducer;
export type orderMapFiltersState = State;

export default ordersMapFilter;
