import React, { Dispatch, memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import CarPark from "../../../../services/CarPark";
import useModelSubscribe from "../../../../hooks/useModelSubscribe2";
import {
	useTaxiServiceFilterAccess,
	useCarParkFilterAccess,
} from "../../../../access";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";

const MultiSelectCarParkWithModalBase: React.FC<
	MultiSelectCarParkWithModalBase.Props
> = ({
	value,
	onChange,
	disabled,
	titleText,
	required,
	modalSetting,
	sort,
	allowedTaxiServiceIds,
	all = true,
	accessIds,
	...props
}) => {
	const { t } = useTranslation();

	const { accessTaxiServicesIds } = useTaxiServiceFilterAccess<any>();
	const { isUserFleetOwner } = useCarParkFilterAccess();

	// console.group("Console_Group_DP2");
	// console.log(`value`, value);
	// console.log(`allowedTaxiServiceIds`, allowedTaxiServiceIds);
	// console.log(`accessTaxiServicesIds`, accessTaxiServicesIds);

	// //  console.log(`activeKey`, activeTab);

	// console.groupEnd();

	const optionsSubscribe: CarPark.SubscribeOptions = useMemo(
		() => ({
			...(allowedTaxiServiceIds || accessTaxiServicesIds
				? {
						taxiServiceIds:
							allowedTaxiServiceIds || accessTaxiServicesIds,
				  }
				: {}),
		}),
		[accessTaxiServicesIds, allowedTaxiServiceIds],
	);

	const subscribe = useModelSubscribe(optionsSubscribe, CarPark);

	const items = useMemo(() => {
		let retval = subscribe.models;
		if (Array.isArray(accessIds))
			retval = retval.filter((item) => accessIds.includes(item.id));
		return retval;
	}, [accessIds, subscribe.models]);

	const [error, setError] = useState("");

	const selectOptions = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			items.map((item) => ({
				key: item.id,
				label: item.name,
				value: item.id,
				name: item.name,
				data: item,
			})),
		[items],
	);

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			selectOptions.filter((item) =>
				value.find((data) => data === item.value),
			),

		[selectOptions, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setError("");
			const Ids = newValue.map((item) => item.value);
			onChange(Ids);
		},
		[onChange],
	);

	const texts = useMemo(
		() => ({
			title: t(titleText),
			allText: t(["all_car_parks"]),
			allActiveText: t(["all_active_car_parks"]),
		}),
		[t, titleText],
	);

	return (
		<MultiSelectWithModal
			error={error}
			disabled={disabled}
			value={selected}
			title={texts.title}
			allText={all ? texts.allText : undefined}
			allActiveText={texts.allActiveText}
			options={selectOptions}
			onChange={selectOnChange}
			required={required}
			pullUpItemInsideArray
			hiddenButton={false}
			showSelectAll
			modalSetting={modalSetting}
			sort={{
				show: true,
				active: true,
				...(sort || {}),
			}}
			{...props}
		/>
	);
};

export const MultiSelectCarParkWithModal = memo(
	MultiSelectCarParkWithModalBase,
);

declare namespace MultiSelectCarParkWithModalBase {
	interface Props extends MultiSelectWithModalBase.Setting {
		value: Value;
		onChange: Dispatch<Value>;
		titleText: string | string[];
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];
		setShowModalProps?: (value: boolean) => void;
		allowedTaxiServiceIds?: number[];
		all?: boolean;
		accessIds?: number[];
	}

	type Value = number[];
}

export default MultiSelectCarParkWithModalBase;
