import React, { useCallback, useMemo, useState } from "react";
import { Button, Column, DateAndTimePicker, Row } from "uikit";
import { useTranslation } from "react-i18next";
import { isNumber } from "lodash";

import { Order, Car } from "../../../../../../services";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../redux/store";
import getArchiveOrdersForChart from "../../../../../../redux/services/Order/getArchiveOrdersForChart";
import archiveReportsByOrders, {
	generateTimePeriods,
} from "../../../../../../redux/reducers/Archives/Reports/charts/byOrders";
import {
	useDatePickerLocale,
	useOrderStatistics,
	useModelSubscribe,
} from "../../../../../../hooks";
import binarySearch from "../../../../../../utils/binarySearch";
import {
	useCarParkFilterAccess,
	useTaxiServiceFilterAccess,
} from "../../../../../../access";
import Header from "../../../../../../components/BasicPageLayout/Header";
import CompaniesAndTaxiServicesFilter from "../../../../../../components/CompaniesAndTaxiServicesFilter";

import ChartTypeSelect from "./components/ChartTypeSelect";
import Chart from "./components/Chart";
import IntervalSelect from "./components/IntervalSelect";

const Charts: React.FC = () => {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const locale = useDatePickerLocale();

	const globalLang = useTypedSelector((state) => state.session.language);
	const {
		dateFrom,
		dateTo,
		filter,
		orders: ordersRedux,
	} = useTypedSelector((state) => state.archiveReports.charts.byOrders);
	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);

	const { accessTaxiServicesIds } = useTaxiServiceFilterAccess<Order.Model>();
	const { subCarParkFilterAccess } = useCarParkFilterAccess<Order.Model>();

	const optionsCarService = useMemo<Car.SubscribeOptions>(() => {
		const result: Car.SubscribeOptions = {
			language: globalLang,
			taxiServiceIds: accessTaxiServicesIds,
		};

		return result;
	}, [accessTaxiServicesIds, globalLang]);
	const { models: CarSub } = useModelSubscribe(optionsCarService, Car);

	const dataAndAddPark = ordersRedux.map((order) => ({
		...order,
		carToOrder:
			order.carToOrder?.map((carItem) => {
				const idCar = carItem.car?.id;
				if (!idCar) return carItem;

				const findFullCar = CarSub.find((car) => car.id === idCar);
				if (!findFullCar) return carItem;

				return {
					...carItem,
					car: { ...carItem.car, park: findFullCar.park },
				};
			}) || [],
	}));

	const orders = subCarParkFilterAccess({
		models: dataAndAddPark,
		branchPath: "carToOrder",
		branchPath2: "car.park.id",
		isOrderLogic: true,
		// branchPath3: "responsibleDispatcher.id",
	});

	const {
		overOrdersArr,
		completedOrdersArr,
		unCompletedOrdersArr,
		unclosedOrdersArr,
		dispatcherErrorArr,
		clientRefusalArr,
		driverRefusalArr,
		noCarAvailableArr,
		calculationErrorArr,
		completedByTransferArr,
		completedFromTransferArr,
		completedByOwnDriverArr,
		ownCompletedOrdersArr,
		firstOrdersClientArr,
		firstOrdersDoneClientArr,
	} = useOrderStatistics(orders);

	const taxiServiceIds = useMemo(
		() =>
			// eslint-disable-next-line no-nested-ternary
			filter.taxiServices[0] !== "all"
				? (filter.taxiServices as number[])
				: filter.companies[0] !== "all"
				? taxiServices
						?.filter(
							(taxiService) =>
								isNumber(taxiService.company?.id) &&
								(filter.companies as Array<number>).includes(
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									taxiService.company!.id,
								),
						)
						.map((taxiService) => taxiService.id)
				: undefined,
		[filter.companies, filter.taxiServices, taxiServices],
	);

	// const orderTypes = [
	// 	"overOrders",
	// 	"completedOrders",
	// 	"unCompletedOrders",
	// 	"unclosedOrders",
	// 	"ownCompletedOrders",
	// 	"completedByOwnDriver",

	// 	"driverRefusal",
	// 	"clientRefusal",
	// 	"noCarAvailable",
	// 	"calculationError",
	// 	"dispatcherError",

	// 	"completedFromTransfer",
	// 	"completedByTransfer",
	// 	"firstOrdersClient",
	// 	"firstOrdersDoneClient",
	// ];

	// const orderColors = {
	// 	overOrders: "#49699c",
	// 	completedOrders: "#e89e3b",
	// 	unCompletedOrders: "#f2ea91",
	// 	unclosedOrders: "#b4b4b4",
	// 	driverRefusal: "#707070",
	// 	clientRefusal: "#1d7b63",
	// 	noCarAvailable: "#f4c26c",
	// 	calculationError: "#af0915",
	// 	dispatcherError: "#6db59d",
	// 	ownCompletedOrders: "#ee5018",
	// 	completedByOwnDriver: "#46699f",
	// 	completedFromTransfer: "#2b416a",
	// 	completedByTransfer: "#4c98a8",
	// 	firstOrdersClient: "#f5f5f5", // !
	// 	firstOrdersDoneClient: "#f5f5c5", // !
	// };

	// const colorValues = Object.values(orderColors);

	const orderTypes: Charts.OrderContentKeys[] = useMemo(
		() => [
			"overOrders",
			"completedOrders",
			"unCompletedOrders",
			"unclosedOrders",
			"ownCompletedOrders",
			"completedByOwnDriver",
			"driverRefusal",
			"clientRefusal",
			"noCarAvailable",
			"calculationError",
			"dispatcherError",
			"completedFromTransfer",
			"completedByTransfer",
			"firstOrdersClient",
			"firstOrdersDoneClient",
		],
		[],
	);

	const orderColors = useMemo<Record<Charts.OrderContentKeys, string>>(
		() => ({
			overOrders: "#63b898",
			completedOrders: "#4467a1",
			unCompletedOrders: "#f2ea91",
			unclosedOrders: "#a2d3da",
			ownCompletedOrders: "#ec9b39",
			completedByOwnDriver: "#f24c12",
			driverRefusal: "#707070",
			clientRefusal: "#1d7b63",
			noCarAvailable: "#f4c26c",
			calculationError: "#af0915",
			dispatcherError: "#ede796",
			completedFromTransfer: "#2b416a",
			completedByTransfer: "#4c98a8",
			firstOrdersClient: "#b5b5b5",
			firstOrdersDoneClient: "#4467a0",
		}),
		[],
	);

	const sortedColorValues = useMemo(
		() => orderTypes.map((orderType) => orderColors[orderType]),
		[orderTypes, orderColors],
	);

	const fetchOrders = useCallback(async () => {
		dispatch(
			getArchiveOrdersForChart({
				dateRange: {
					from: dateFrom,
					to: dateTo,
				},
				taxiServiceIds,
			}),
		);
	}, [dateFrom, dateTo, dispatch, taxiServiceIds]);

	const [chartType, setChartType] = useState<Chart.ChartType>("columnar");
	const chartData = useMemo(() => {
		// if (orders.length === 0) return null;

		const timePeriods = generateTimePeriods(
			filter.dateRangeLiteral,
			filter.interval,
		);

		const orderObkects: Record<Charts.OrderContentKeys, Order.Model[]> = {
			overOrders: overOrdersArr,
			completedOrders: completedOrdersArr,
			unCompletedOrders: unCompletedOrdersArr,
			unclosedOrders: unclosedOrdersArr,
			ownCompletedOrders: ownCompletedOrdersArr,
			completedByOwnDriver: completedByOwnDriverArr,
			driverRefusal: driverRefusalArr,
			clientRefusal: clientRefusalArr,
			noCarAvailable: noCarAvailableArr,
			calculationError: calculationErrorArr,
			dispatcherError: dispatcherErrorArr,
			completedFromTransfer: completedFromTransferArr,
			completedByTransfer: completedByTransferArr,
			firstOrdersClient: firstOrdersClientArr,
			firstOrdersDoneClient: firstOrdersDoneClientArr,
		};

		const orderArrays = orderTypes.map(
			(orderType) => orderObkects[orderType],
		);

		const ordersData: Record<string, { label: string; data: number[] }> =
			orderTypes.reduce((acc, key) => {
				acc[key] = {
					label: `pages.mainPage.pages.archives.tabs.orders.bottomStatisticsField.${key}`,
					data: Array<number>(timePeriods.length).fill(0),
				};
				return acc;
			}, {} as Record<string, { label: string; data: number[] }>);

		// const total = {
		// 	label: `mainPage.reports.charts.byOrders.total`,
		// 	data: Array<number>(timePeriods.length).fill(0),
		// };
		// const opened = {
		// 	label: `mainPage.reports.charts.byOrders.opened`,
		// 	data: Array<number>(timePeriods.length).fill(0),
		// };
		// const closed = {
		// 	label: `mainPage.reports.charts.byOrders.closed`,
		// 	data: Array<number>(timePeriods.length).fill(0),
		// };

		orders.forEach((order) => {
			const timePeriodKey = binarySearch(
				timePeriods,
				new Date(order.createdAt).getTime(),
			);
			orderTypes.forEach((orderType, index) => {
				const orderArray = orderArrays[index];
				if (orderArray.find((_order) => _order.id === order.id)) {
					++ordersData[orderType].data[timePeriodKey];
				}
			});

			// ++total.data[timePeriodKey];

			// if (order.status === OrderStatus.CREATED) {
			// 	++opened.data[timePeriodKey];
			// } else if (order.status === OrderStatus.CLOSED) {
			// 	++closed.data[timePeriodKey];
			// }
		});

		const ordersDataArray = Object.entries(ordersData).map(
			([key, value]) => ({
				...value,
				key,
			}),
		);

		const data: Chart.OrderChartData = {
			timePeriods,
			// sets: [total, opened, closed],
			sets: ordersDataArray,
		};

		return data;
	}, [
		calculationErrorArr,
		clientRefusalArr,
		completedByOwnDriverArr,
		completedByTransferArr,
		completedFromTransferArr,
		completedOrdersArr,
		dispatcherErrorArr,
		driverRefusalArr,
		filter.dateRangeLiteral,
		filter.interval,
		firstOrdersClientArr,
		firstOrdersDoneClientArr,
		noCarAvailableArr,
		orderTypes,
		orders,
		overOrdersArr,
		ownCompletedOrdersArr,
		unCompletedOrdersArr,
		unclosedOrdersArr,
	]);

	const localizedChartData = useMemo(
		() =>
			chartData && {
				timePeriods: chartData.timePeriods.map((tp) =>
					new Date(tp).toLocaleString(
						globalLang,
						filter.interval.format,
					),
				),
				sets: chartData.sets.map((set) => ({
					...set,
					label: t(set.label),
				})),
			},
		[chartData, globalLang, filter.interval.format, t],
	);

	return (
		<Column maxedHeight justify="stretch" align="center">
			<Header>
				<Row maxedWidth gaps="5px*" justify="start" align="center">
					{t(`from`, { context: "capitalized" })}
					<DateAndTimePicker
						value={useMemo(() => new Date(dateFrom), [dateFrom])}
						onChange={(newDate) => {
							if (newDate)
								dispatch(
									archiveReportsByOrders.actions.setDateFrom(
										newDate.getTime(),
									),
								);
						}}
						locale={locale}
					/>

					{t(`to`)}
					<DateAndTimePicker
						value={useMemo(() => new Date(dateTo), [dateTo])}
						onChange={(newDate) => {
							if (newDate)
								dispatch(
									archiveReportsByOrders.actions.setDateTo(
										newDate.getTime(),
									),
								);
						}}
						locale={locale}
					/>

					<Row
						style={{
							flexGrow: 1,
							maxWidth: "800px",
						}}
						gaps="5px*"
						align="center"
						sizes="1fr!*"
					>
						<CompaniesAndTaxiServicesFilter
							companies={filter.companies}
							taxiServices={filter.taxiServices}
							language={globalLang}
							onChangeCompanies={(companyFilter) => {
								dispatch(
									archiveReportsByOrders.actions.setCompanyFilter(
										companyFilter,
									),
								);
							}}
							onChangeTaxiServices={(taxiServiceFilter) => {
								dispatch(
									archiveReportsByOrders.actions.setTaxiServiceFilter(
										taxiServiceFilter,
									),
								);
							}}
						/>

						<Button.Button
							variant="primary"
							text={t(`generate`) || "Generate"}
							onClick={fetchOrders}
						/>

						<ChartTypeSelect
							value={chartType}
							onChange={setChartType}
						/>

						<IntervalSelect
							value={filter.interval.label}
							onChange={(intervalLabel) => {
								dispatch(
									archiveReportsByOrders.actions.setInterval(
										intervalLabel,
									),
								);
							}}
						/>
					</Row>
				</Row>
			</Header>

			<Column
				maxedWidth
				maxedHeight
				justify="center"
				align="center"
				style={{
					paddingBlock: "8px",
					paddingInline: "10px",
					overflow: "hidden",
					position: "relative",
				}}
			>
				{localizedChartData ? (
					<Chart
						data={localizedChartData}
						type={chartType}
						colors={sortedColorValues}
					/>
				) : (
					<div>{t(`mainPage.reports.charts.noChart`)}</div>
				)}
			</Column>
		</Column>
	);
};

declare namespace Charts {
	interface Props {}

	type OrderContentKeys =
		| "overOrders"
		| "completedOrders"
		| "unCompletedOrders"
		| "unclosedOrders"
		| "driverRefusal"
		| "clientRefusal"
		| "noCarAvailable"
		| "calculationError"
		| "dispatcherError"
		| "ownCompletedOrders"
		| "completedByOwnDriver"
		| "completedFromTransfer"
		| "completedByTransfer"
		| "firstOrdersClient"
		| "firstOrdersDoneClient";
}

export default Charts;
