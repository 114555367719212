import React, { useLayoutEffect, useMemo } from "react";

import {
	CarClass,
	Service,
	TaxiService,
	Executor,
	ExecutorGroup,
	ExecutorRate,
	Role,
	AdditionalTariff,
	OrderSettings,
	Tariff,
} from "../../services";
import { ExecutorSubscribeType } from "../../services/Executor";
import { useTypedDispatch, useTypedSelector } from "../../redux/store";
import { preferences, globalState } from "../../redux/reducers";
import taxiServices from "../../redux/reducers/taxiServices";
import additionalTariffs from "../../redux/reducers/additionalTariffs";
import order from "../../redux/reducers/settings/order";
import getCompanies from "../../redux/services/Company/getCompanies";
import setRates from "../../redux/actions/Preferences/Rates/setRates";
import { useModelSubscribe } from "../../hooks";
import {
	useTaxiServiceFilterAccess,
	useCarParkFilterAccess,
} from "../../access";

const BaseModelsLoader: React.FC<BaseModelsLoader.Props> = () => {
	const dispatch = useTypedDispatch();
	const { subTaxiServiceFilterAccess } =
		useTaxiServiceFilterAccess<Executor.Model>();
	const { subCarParkFilterAccess } = useCarParkFilterAccess<Executor.Model>();

	const connected = useTypedSelector((state) => state.prpc.connected);
	const authorized = useTypedSelector((state) => state.prpc.authorized);
	const language = useTypedSelector((state) => state.session.language);

	const canSubscribe: boolean = useMemo(
		() => connected && authorized,
		[connected, authorized],
	);

	const ratesSubscription = useModelSubscribe({}, Tariff);
	const subscribeTaxiService = useModelSubscribe({}, TaxiService);
	const subscribeCarClass = useModelSubscribe({}, CarClass);

	const subscribeExecutor = useModelSubscribe(
		{
			language,
			subscribeType: ExecutorSubscribeType.FRONT,
		},
		Executor,
	);

	const subscribeExecutorGroup = useModelSubscribe(
		{ lang: language },
		ExecutorGroup,
	);
	const subscribeExecutorRate = useModelSubscribe(
		{ lang: language },
		ExecutorRate,
	);
	const subscribeRole = useModelSubscribe({ language }, Role);
	const subscribeAdditionalTariff = useModelSubscribe({}, AdditionalTariff);
	const subscribeOrderSettings = useModelSubscribe(
		{ includeTaxiServices: true },
		OrderSettings,
	);

	const subscribeService = useModelSubscribe({}, Service);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeService.models : [];
		dispatch(preferences.services.data.setModels(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeService.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeCarClass.models : [];
		dispatch(preferences.carClasses.data.setModels(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeCarClass.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeTaxiService.models : [];

		dispatch(taxiServices.actions.setModels(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeTaxiService.models]);

	useLayoutEffect(() => {
		dispatch(setRates(ratesSubscription.models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, ratesSubscription.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeExecutor.models : [];

		const dataAfterAccessTaxiServer = subTaxiServiceFilterAccess({
			models,
			branchPath: "taxiService.id",
		});

		// const dataAfterAccessPark = subCarParkFilterAccess({
		// 	models: dataAfterAccessTaxiServer,
		// 	branchPath: "cars",
		// 	branchPath2: "park.id",
		// 	isUserCreated: true, // * Expands the list of performers assigned by the responsible dispatcher. This is specifically required for the accounts page, for performers.
		// 	branchPath3: "responsibleDispatcher.id",
		// });
		dispatch(
			globalState.executors.data.setSubscriptionBasicData(
				dataAfterAccessTaxiServer,
			),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeExecutor.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeExecutorGroup.models : [];
		dispatch(
			globalState.executorGroup.data.setSubscriptionBasicData(models),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeExecutorGroup.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeExecutorRate.models : [];
		dispatch(
			globalState.executorRate.data.setSubscriptionBasicData(models),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeExecutorRate.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeRole.models : [];
		dispatch(globalState.role.data.setSubscriptionBasicData(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeRole.models]);

	useLayoutEffect(() => {
		const models = canSubscribe ? subscribeAdditionalTariff.models : [];
		dispatch(additionalTariffs.actions.setModels(models));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeAdditionalTariff.models]);

	useLayoutEffect(() => {
		const modelFirst =
			(canSubscribe && subscribeOrderSettings?.models?.[0]) || null;

		if (modelFirst) {
			dispatch(order.actions.set(modelFirst.settings));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canSubscribe, subscribeOrderSettings?.models]);

	useLayoutEffect(() => {
		dispatch(getCompanies());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <></>;
};

declare namespace BaseModelsLoader {
	interface Props {}
}

export default BaseModelsLoader;
