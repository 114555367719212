/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

import { ReduxReducer } from "../../../../interface";
import { Orders } from "../../interface";

import {
	State,
	ModalFilter,
	MessagesTypeFilter,
	BodyFilter,
} from "./interface";

export const baseFilters: ModalFilter = {
	callSigns: ["all"],
	companyIds: ["all"],
	taxiServiceIds: ["all"],
	executorIds: ["all"],
	fleetIds: ["all"],
	executorGroupIds: [],
};

const initialBodyFilter: BodyFilter = {
	criteria: baseFilters,
};

const initialState: State = {
	filterExecutorsRead: initialBodyFilter,
	filterExecutorsWrite: initialBodyFilter,
};

const setMegaFilter: ReduxReducer<
	State,
	{
		type: MessagesTypeFilter;
		data: ModalFilter;
	}
> = (state, action) => {
	state[action.payload.type].criteria = action.payload.data;
};

const orderChatsFilters = createSlice({
	name: Orders.ConstantOrdersSlice.CHAT_FILTERS,
	initialState,
	reducers: {
		setMegaFilter,
	},
});

export const orderChatsFiltersAction = orderChatsFilters.actions;
export const orderChatsFiltersReducer = orderChatsFilters.reducer;
export type orderChatsFiltersState = State;

export default orderChatsFilters;
