import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Button, Icon } from "uikit";
import { useTranslation } from "react-i18next";

import { Executor, Language } from "../../../../../../../services";
import { useTypedSelector } from "../../../../../../../redux/store";
import { Orders } from "../../../../../../../redux/reducers/Orders";
import { baseFilters } from "../../../../../../../redux/reducers/Orders/Chat/filter";
import { ModalFilter } from "../../../../../../../redux/reducers/Orders/Chat/filter/interface";
import useCompanyAndTaxiServiceIdsFilter from "../../../../../../../hooks/useCompanyAndTaxiServiceIdsFilter";
import useObjectEditor from "../../../../../../../hooks/useObjectEditor";
import {
	useExecutorIdsDecoder,
	useCarParkIdsDecoder,
} from "../../../../../../../hooks";
import {
	useTaxiServiceFilterAccess,
	useCarParkFilterAccess,
} from "../../../../../../../access";
import {
	MultiSelectExecutorWithModal,
	MultiSelectExecutorGroupWithModal,
	MultiSelectCarParkWithModal,
	StyledColumn,
	StyledRow,
} from "../../../../../../common";

import { Content, Header } from "./components";

const Filter: React.FC<Filter.Props> = ({
	filter,
	setFilter,
	language,
	onClose,
	onSave,
	criteria,
}) => {
	const { t } = useTranslation();
	const allExecutorIds = useExecutorIdsDecoder();
	const allCarParkIds = useCarParkIdsDecoder();
	const { accessTaxiServicesIds } = useTaxiServiceFilterAccess<any>();
	const { isUserFleetOwner } = useCarParkFilterAccess<any>();

	const executors =
		useTypedSelector(
			(state) => state.globalState.executors.data.subscription.basic,
		) || [];

	const [companyAndTaxiServiceError, setCompanyAndTaxiServiceError] =
		useState({
			taxiService: false,
			company: false,
		});
	const [requestData, setRequestData] =
		useState<Orders.Chat.ModalFilter>(criteria);

	const clearErrors = useCallback(() => {
		setCompanyAndTaxiServiceError({ company: false, taxiService: false });
	}, []);

	useLayoutEffect(() => {
		setRequestData(filter);
	}, [filter]);

	const executorIdsRedux = useMemo(
		() => executors.map((executor) => executor.id),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[executors.length],
	);

	const valueEditor = useObjectEditor(requestData, setRequestData);

	const fleetIds = valueEditor.useGetter("fleetIds");
	const setFleetIds = valueEditor.useSetter("fleetIds");

	const executorIds = valueEditor.useGetter("executorIds");
	const setExecutorIds = valueEditor.useSetter("executorIds");

	const executorGroupIds = valueEditor.useGetter("executorGroupIds");
	const setExecutorGroupIds = valueEditor.useSetter("executorGroupIds");

	const companyIds = valueEditor.useGetter("companyIds");
	const setCompanyIds = valueEditor.useSetter("companyIds");

	const taxiServiceIds = valueEditor.useGetter("taxiServiceIds");
	const setTaxiServiceIds = valueEditor.useSetter("taxiServiceIds");

	const executorIdsAll = useMemo(
		() => allExecutorIds(["all"]),
		[allExecutorIds],
	);

	const _executorIds = useMemo(
		() => allExecutorIds(executorIds),
		[executorIds, allExecutorIds],
	);

	const fleetIdsAll = useMemo(() => allCarParkIds(["all"]), [allCarParkIds]);

	const _fleetIds = useMemo(
		() => allCarParkIds(fleetIds),
		[fleetIds, allCarParkIds],
	);

	const onChangeFleetIds = useCallback(
		(fleetIds: number[]) => {
			if (fleetIds.length > 0 || fleetIdsAll.length === fleetIds.length) {
				setFleetIds(["all"]);
			}

			setFleetIds(fleetIds);
		},
		[fleetIdsAll.length, setFleetIds],
	);

	const onChangeExecutorIds = useCallback(
		(executorIds: number[]) => {
			if (
				executorIds.length > 0 ||
				executorIdsAll.length === executorIds.length
			) {
				setExecutorIds(["all"]);
			}

			setExecutorIds(executorIds);
		},
		[executorIdsAll.length, setExecutorIds],
	);

	const onChangeCompanyIds = useCallback(
		(companyIds: number[] | ["all"]) => {
			setCompanyIds(companyIds);
		},
		[setCompanyIds],
	);

	const onChangeTaxiServiceIds = useCallback(
		(taxiServiceIds: number[] | ["all"]) => {
			setTaxiServiceIds(taxiServiceIds);
		},
		[setTaxiServiceIds],
	);

	const clearFilters = useCallback(() => {
		// setRequestData({ ...baseFilters, executorIds: executorIdsRedux });
		setRequestData(baseFilters);
		clearErrors();
	}, [clearErrors]);

	const [
		possibleCompanies,
		possibleTaxiServices,
		companyIdsFilter,
		taxiServiceIdsFilter,
		setCompanyIdsFilter,
		setTaxiServiceIdsFilter,
	] = useCompanyAndTaxiServiceIdsFilter(
		companyIds,
		taxiServiceIds,
		onChangeCompanyIds,
		onChangeTaxiServiceIds,
	);

	const saveFilter = useCallback(() => {
		if (!requestData.taxiServiceIds.length)
			companyAndTaxiServiceError.taxiService = true;

		if (!requestData.companyIds.length)
			companyAndTaxiServiceError.company = true;

		if (
			!requestData.taxiServiceIds.length ||
			!requestData.companyIds.length
		) {
			return setCompanyAndTaxiServiceError(() => ({
				...companyAndTaxiServiceError,
			}));
		}

		setFilter(requestData);
		onSave(requestData);
		onClose();
		clearErrors();
		return true;
	}, [
		clearErrors,
		companyAndTaxiServiceError,
		onClose,
		onSave,
		requestData,
		setFilter,
	]);

	const cancelFilter = useCallback(() => {
		onClose();
		clearErrors();
	}, [clearErrors, onClose]);

	const optionsExceptionWithModal = useMemo(() => {
		// const filterTaxiServiceIds: number[] = [];

		// if (taxiServiceIds) {
		// 	for (let i = 0; i < taxiServiceIds.length; i++) {
		// 		const id = taxiServiceIds[i];
		// 		if (id !== "all") {
		// 			filterTaxiServiceIds.push(Number(id));
		// 		}
		// 	}
		// }

		const retval: Executor.SubscribeOptions = {
			order: { callSign: "ASC" },
			taxiServiceIds: accessTaxiServicesIds,
		};

		return retval;
	}, [accessTaxiServicesIds]);

	return (
		<StyledColumn
			gap="16px 20px"
			w="500px"
			p="16px 20px"
			br="5px"
			bgC="#ffffff"
			shadow="0px 2px 10px rgba(0, 0, 0, 0.15)"
		>
			<Header onClose={onClose} />
			<StyledColumn gap="12px">
				<Content
					language={language}
					possibleCompanies={possibleCompanies}
					possibleTaxiServices={possibleTaxiServices}
					companyIds={companyIdsFilter}
					taxiServiceIds={taxiServiceIdsFilter}
					setCompanyIds={setCompanyIdsFilter}
					setTaxiServiceIds={setTaxiServiceIdsFilter}
					error={companyAndTaxiServiceError}
					onChangeError={setCompanyAndTaxiServiceError}
				/>

				<MultiSelectExecutorWithModal
					value={_executorIds}
					onChange={onChangeExecutorIds}
					titleText={
						t(
							"orderPageWidgets.chat.executorsFilterPanel.popover.str200",
						) ?? ""
					}
					modalSetting={{
						zIndex: 999,
						containerId: "root",
					}}
					options={optionsExceptionWithModal}
				/>
				<MultiSelectExecutorGroupWithModal
					value={executorGroupIds}
					onChange={setExecutorGroupIds}
					titleText={
						t(
							"orderPageWidgets.chat.executorsFilterPanel.popover.str201",
						) ?? ""
					}
					modalSetting={{
						zIndex: 999,
						containerId: "root",
					}}
				/>
				<MultiSelectCarParkWithModal
					value={_fleetIds}
					onChange={onChangeFleetIds}
					disabled={isUserFleetOwner && fleetIdsAll.length === 1}
					all={!isUserFleetOwner && fleetIdsAll.length > 1}
					accessIds={fleetIdsAll}
					titleText={
						t(
							"orderPageWidgets.chat.executorsFilterPanel.popover.str202",
						) ?? ""
					}
					modalSetting={{
						zIndex: 999,
						containerId: "root",
					}}
				/>
			</StyledColumn>
			<StyledRow justify="space-between">
				<Button.Button
					icon={<Icon size={18} id="refresh" />}
					variant="secondary"
					onClick={clearFilters}
				/>
				<StyledRow gap="20px">
					<Button.Button
						variant="secondary"
						text={t("cancel") || "Cancel"}
						onClick={cancelFilter}
					/>
					<Button.Button
						text={
							t(
								"orderPageWidgets.chat.executorsFilterPanel.popover.str0",
							) ?? ""
						}
						onClick={saveFilter}
					/>
				</StyledRow>
			</StyledRow>
		</StyledColumn>
	);
};

declare namespace Filter {
	interface Props {
		language: Language;
		filter: Orders.Chat.ModalFilter;
		setFilter: React.Dispatch<
			React.SetStateAction<Orders.Chat.ModalFilter>
		>;
		onClose: () => void;
		onSave: (data: Orders.Chat.ModalFilter) => void;
		criteria: ModalFilter;
	}
}

export default Filter;
