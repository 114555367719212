import React, { Dispatch, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CheckBoxWithContent } from "uikit";

import { useTypedSelector } from "../../../../../../../../../../../redux/store";
import useObjectEditor from "../../../../../../../../../../../hooks/useObjectEditor";
import { useCarParkIdsDecoder } from "../../../../../../../../../../../hooks";
import { useCarParkFilterAccess } from "../../../../../../../../../../../access";
import LabeledField from "../../../../../../../../../../LabeledField";
import {
	MultiSelectExecutorGroupWithModal,
	MultiSelectCarParkWithModal,
	StyledColumn,
	StyledGrid,
} from "../../../../../../../../../../common";

import TaxiServices from "./components/TaxiServices";
import MultiSelectList from "./components/MultiSelectList";
import { getShifts, getStatuses } from "./data";

const Form: React.FC<Form.Props> = ({
	value,
	onChange,
	setShowModalPropsForExecutorGroup,
	setShowModalPropsForCarPark,
}) => {
	const { t } = useTranslation();
	const allCarParkIds = useCarParkIdsDecoder();
	const { isUserFleetOwner } = useCarParkFilterAccess<any>();

	const shifts = useMemo(() => getShifts(t), [t]);
	const statuses = useMemo(() => getStatuses(t), [t]);

	const language = useTypedSelector((state) => state.session.language);

	const valueEditor = useObjectEditor(value, onChange);

	const taxiServicesValue = valueEditor.usePicker([
		"companyIds",
		"taxiServiceIds",
	]);

	const assignValue = valueEditor.useAssigner();

	const onActiveOrderValue = valueEditor.useGetter("onActiveOrder");
	const onActiveOrderOnChange = valueEditor.useSetter("onActiveOrder");

	const statusesValue = valueEditor.useGetter("statuses");
	const statusesOnChange = valueEditor.useSetter("statuses");

	const shiftsValue = valueEditor.useGetter("shifts");
	const shiftsOnChange = valueEditor.useSetter("shifts");

	const fleetIds = valueEditor.useGetter("fleetIds");
	const setFleetIds = valueEditor.useSetter("fleetIds");
	const executorGroupIds = valueEditor.useGetter("executorGroupIds");
	const setExecutorGroupIds = valueEditor.useSetter("executorGroupIds");

	const fleetIdsAll = useMemo(() => allCarParkIds(["all"]), [allCarParkIds]);

	const _fleetIds = useMemo(
		() => allCarParkIds(fleetIds),
		[fleetIds, allCarParkIds],
	);

	const onChangeFleetIds = useCallback(
		(fleetIds: number[]) => {
			if (fleetIds.length > 0 || fleetIdsAll.length === fleetIds.length) {
				setFleetIds(["all"]);
			}

			setFleetIds(fleetIds);
		},
		[fleetIdsAll.length, setFleetIds],
	);

	return (
		<StyledGrid areas="" gap="12px" columns="1fr auto auto">
			<StyledColumn gap="12px">
				<TaxiServices
					value={taxiServicesValue}
					disabled={onActiveOrderValue}
					language={language}
					onChange={assignValue}
				/>
				<LabeledField
					label={
						t(
							"orderPageWidgets.chat.executorsFilterPanel.popover.str201",
						) ?? ""
					}
				>
					<MultiSelectExecutorGroupWithModal
						value={executorGroupIds || []}
						onChange={setExecutorGroupIds}
						titleText={
							t(
								"orderPageWidgets.chat.executorsFilterPanel.popover.str201",
							) ?? ""
						}
						modalSetting={{
							zIndex: 999,
							containerId: "root",
						}}
						setShowModalProps={setShowModalPropsForExecutorGroup}
					/>
				</LabeledField>

				<LabeledField
					label={
						t(
							"orderPageWidgets.chat.executorsFilterPanel.popover.str202",
						) ?? ""
					}
				>
					<MultiSelectCarParkWithModal
						value={_fleetIds || []}
						onChange={onChangeFleetIds}
						disabled={isUserFleetOwner && fleetIdsAll.length === 1}
						all={!isUserFleetOwner && fleetIdsAll.length > 1}
						accessIds={fleetIdsAll}
						titleText={
							t(
								"orderPageWidgets.chat.executorsFilterPanel.popover.str202",
							) ?? ""
						}
						modalSetting={{
							zIndex: 999,
							containerId: "root",
						}}
						setShowModalProps={setShowModalPropsForCarPark}
					/>
				</LabeledField>
			</StyledColumn>
			<StyledColumn gap="20px">
				<CheckBoxWithContent
					value={onActiveOrderValue}
					gap="10px"
					onChange={onActiveOrderOnChange}
				>
					{t(
						"orderPageWidgets.map2.header.filters.content.form.str150",
					) ?? ""}
				</CheckBoxWithContent>
				<MultiSelectList
					value={statusesValue}
					disabled={onActiveOrderValue}
					label={
						t(
							"orderPageWidgets.map2.header.filters.content.form.str0",
						) ?? ""
					}
					options={statuses}
					onChange={statusesOnChange}
				/>
			</StyledColumn>
			<MultiSelectList
				value={shiftsValue}
				disabled={onActiveOrderValue}
				label={
					t(
						"orderPageWidgets.map2.header.filters.content.form.str1",
					) ?? ""
				}
				options={shifts}
				onChange={shiftsOnChange}
			/>
		</StyledGrid>
	);
};

declare namespace Form {
	interface Value {
		onActiveOrder: boolean;

		companyIds: number[] | ["all"];
		taxiServiceIds: number[] | ["all"];

		statuses: any;
		shifts: any;

		executorGroupIds: number[];
		fleetIds: number[] | ["all"];
	}

	interface Props {
		value: Value;

		onChange: Dispatch<Value>;

		setShowModalPropsForExecutorGroup: (value: boolean) => void;
		setShowModalPropsForCarPark: (value: boolean) => void;
	}
}

export default Form;
