/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import React, {
	Dispatch,
	Key,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Column, react } from "uikit";

import Language from "../../services/Language";
import Dispatcher from "../../services/Dispatcher";
import { useTypedSelector } from "../../redux/store";
import {
	useObjectEditor,
	useDispatcherSubHandler,
	useTaxiServiceIdsDecoder,
} from "../../hooks";
import useModelSubscribe from "../../hooks/useModelSubscribe";
import mapByKey from "../../utils/mapByKey";
import TableSelect from "../TableSelect";
import LightTable from "../LightTable";

import Root from "./components/Root";
import Header from "./components/Header";
import InternalController from "./Controller";

const defaultColumnIds = ["fullName", "role", "alias", "access"];

const DispatcherSelectTab = memo(
	react.withController<
		DispatcherSelectTab.PropsBase,
		DispatcherSelectTab.Controller
	>(
		({
			value,
			disabled,
			visible,
			language,
			allowedTaxiServiceIds,
			onChange,
		}) => {
			const { t } = useTranslation();
			const valueEditor = useObjectEditor(value, onChange);
			const { getSortAndLimitDispatcherSubHandler } =
				useDispatcherSubHandler();

			const [filters, setFilters] = useState<Header.Filters.Value>({
				companyIds: ["all"],
				taxiServiceIds: ["all"],
				statuses: ["active", "blocked", "dismissed"],
				search: "",
			});

			const decodeTaxiServiceIds = useTaxiServiceIdsDecoder();

			const { models: taxiServices } = useTypedSelector(
				(state) => state.taxiServices,
			);

			const taxiServiceById = useMemo(
				() => mapByKey(taxiServices, "id"),
				[taxiServices],
			);

			const [sort, setSort] = useState<TableSelect.Sort>({});
			const [columIds, setColumnIds] =
				useState<string[]>(defaultColumnIds);

			const modelSubscriptionOptions =
				useMemo<Dispatcher.SubscribeOptions>(() => {
					const result: Dispatcher.SubscribeOptions = {
						taxiServiceIds: decodeTaxiServiceIds(
							filters.companyIds,
							filters.taxiServiceIds,
						),
						statuses: filters.statuses,
						language,
						query: filters.search,
					};

					return result;
				}, [
					decodeTaxiServiceIds,
					filters.companyIds,
					filters.search,
					filters.statuses,
					filters.taxiServiceIds,
					language,
				]);

			const data = useModelSubscribe(
				modelSubscriptionOptions,
				Dispatcher,
			);

			const items = useMemo(() => {
				const options: Dispatcher.SubscribeOptions = {
					order:
						typeof sort.column === "string" &&
						typeof sort.type === "string"
							? ({
									[sort.column]: sort.type,
							  } as Dispatcher.SubscribeOptions["order"])
							: ({} as Dispatcher.SubscribeOptions["order"]),
				};

				const cacheDispatcherSub = data?.cache ?? [];

				return getSortAndLimitDispatcherSubHandler(
					options,
					cacheDispatcherSub,
				);
			}, [
				data?.cache,
				getSortAndLimitDispatcherSubHandler,
				sort.column,
				sort.type,
			]);

			const tableSelectValue = valueEditor.useGetter("dispatcherIds");
			const tableSelectOnChange = valueEditor.useSetter("dispatcherIds");

			const tableSelectOptions = useMemo(
				() => items.map((item) => ({ key: item.id, ...item })),
				[items],
			);

			const tableSelectIsOptionActive = useCallback(
				(option: TableSelect.Option<Dispatcher.Model>) =>
					option.status === "active",
				[],
			);

			const tableSelectColumns = useMemo<TableSelect.Column[]>(
				() => [
					{
						id: "fullName",
						name: t("dispatcherSelectTab.str150") ?? "",
						render: () => (
							<LightTable.Column flexGrow={1} sortable>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("dispatcherSelectTab.str150") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell
									verticalAlign="middle"
									dataKey="fullName"
								>
									{(item) =>
										`${item.person.lastName ?? ""} ${
											item.person.firstName ?? ""
										} ${
											item.person.fatherName ?? ""
										}`.trim()
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
					{
						id: "role",
						name: t("dispatcherSelectTab.str200") ?? "",
						render: () => (
							<LightTable.Column width={140} sortable>
								<LightTable.HeaderCell>
									{t("dispatcherSelectTab.str151") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell dataKey="role">
									{(rowData) =>
										rowData.roles
											?.map((role) => role.name)
											.join(", ") ?? ""
									}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},

					{
						id: "alias",
						name: t("dispatcherSelectTab.str152") ?? "",
						render: () => (
							<LightTable.Column width={200} sortable>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("dispatcherSelectTab.str152") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell
									verticalAlign="middle"
									dataKey="alias"
								>
									{(item) => item.alias}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},

					{
						id: "access",
						name: t("dispatcherSelectTab.str153") ?? "",
						render: () => (
							<LightTable.Column flexGrow={1} sortable>
								<LightTable.HeaderCell verticalAlign="middle">
									{t("dispatcherSelectTab.str153") ?? ""}
								</LightTable.HeaderCell>
								<LightTable.Cell
									verticalAlign="middle"
									dataKey="taxiService"
								>
									{(item) => (
										<div
											style={{
												textOverflow: "ellipsis",
												overflow: "hidden",
												whiteSpace: "nowrap",
											}}
										>
											{(item.taxiServiceIds as number[])
												.map(
													(taxiServiceId) =>
														taxiServiceById[
															taxiServiceId
														]?.settlement[
															language
														] ?? "",
												)
												.filter(
													(taxiServiceName) =>
														taxiServiceName,
												)
												.join(", ")}
										</div>
									)}
								</LightTable.Cell>
							</LightTable.Column>
						),
					},
				],
				[language, taxiServiceById, t],
			);

			return (
				<Root hasPaddings={false} visible={visible}>
					<Column sizes="auto! 1fr" maxedWidth maxedHeight>
						<Header
							filters={filters}
							allowedTaxiServiceIds={allowedTaxiServiceIds}
							language={language}
							onChangeFilters={setFilters}
						/>
						<TableSelect
							value={tableSelectValue}
							sort={sort}
							columnIds={columIds}
							disabled={disabled}
							options={tableSelectOptions}
							defaultColumnIds={defaultColumnIds}
							isOptionActive={tableSelectIsOptionActive}
							Columns={tableSelectColumns}
							onChange={
								tableSelectOnChange as (value: Key[]) => void
							}
							onChangeSort={setSort}
							onChangeColumnIds={setColumnIds}
						/>
					</Column>
				</Root>
			);
		},
		InternalController,
	),
);

declare namespace DispatcherSelectTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		dispatcherIds: number[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;
		allowedTaxiServiceIds: number[];

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default DispatcherSelectTab;
