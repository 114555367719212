import { useCallback, useMemo } from "react";
import { isNumber } from "lodash";

import { useTypedSelector } from "../../redux/store";
import { mapByKey } from "../../utils";
import { useTaxiServiceFilterAccess } from "../../access";

import { useCompanyIdsDecoder } from ".";

function useTaxiServiceIdsDecoder({
	allowedTaxiServiceIds,
}: useTaxiServiceIdsDecoder.Options = {}) {
	const decodeCompanyIds = useCompanyIdsDecoder();
	const { accessTaxiServicesIds } = useTaxiServiceFilterAccess<any>();

	const { models: taxiServices } = useTypedSelector(
		(state) => state.taxiServices,
	);

	const taxiServiceById = useMemo(
		() => mapByKey(taxiServices, "id"),
		[taxiServices],
	);

	const availableTaxiServicesIds = useMemo(
		() =>
			taxiServices
				.map((taxiService) => taxiService.id)
				.filter(
					(taxiServiceId) =>
						allowedTaxiServiceIds?.includes(taxiServiceId) ?? true,
				),
		[allowedTaxiServiceIds, taxiServices],
	);

	const isAllIds = useCallback(
		(
			taxiServices: useTaxiServiceIdsDecoder.EncodedIds,
		): taxiServices is ["all"] => taxiServices[0] === "all",
		[],
	);

	const decoder = useCallback(
		(
			companyIds: useTaxiServiceIdsDecoder.EncodedIds,
			taxiServiceIds: useTaxiServiceIdsDecoder.EncodedIds,
			options: useTaxiServiceIdsDecoder.DecoderOptions = {},
		) => {
			const isOnlyAccessTaxiServicesIds = options.isOnlyAccess
				? accessTaxiServicesIds
				: availableTaxiServicesIds;
			const isOnlyAccessCompaniesIds = options.isOnlyAccess
				? { isOnlyAccess: true }
				: {};

			const decodedCompanyIds = decodeCompanyIds(
				companyIds,
				isOnlyAccessCompaniesIds,
			);

			if (isAllIds(companyIds) && isAllIds(taxiServiceIds))
				return isOnlyAccessTaxiServicesIds;

			if (isAllIds(taxiServiceIds))
				return isOnlyAccessTaxiServicesIds.filter(
					(taxiServiceId) =>
						isNumber(taxiServiceById[taxiServiceId].company) &&
						decodedCompanyIds.includes(
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							taxiServiceById[taxiServiceId].company!.id,
						),
				);

			return isOnlyAccessTaxiServicesIds.filter((taxiServiceId) =>
				taxiServiceIds.includes(taxiServiceId),
			);
		},
		[
			accessTaxiServicesIds,
			availableTaxiServicesIds,
			decodeCompanyIds,
			isAllIds,
			taxiServiceById,
		],
	);

	return decoder;
}

declare namespace useTaxiServiceIdsDecoder {
	interface Options {
		allowedTaxiServiceIds?: number[];
	}

	interface DecoderOptions {
		isOnlyAccess?: boolean;
	}

	type EncodedIds = number[] | ["all"];
}

export default useTaxiServiceIdsDecoder;
