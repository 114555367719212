// import { useMemo } from "react";
// import { isUndefined, isNaN } from "lodash";

// import { Executor } from "../../../services";
// import { Orders } from "../../../redux/reducers/Orders";

// const useFilteredExecutors = (
// 	executors: Executor.Model[],
// 	filters: any /* Orders.Chat.ModalFilter */,
// 	activeFilters: Partial<Record<keyof Orders.Chat.ModalFilter, boolean>> = {},
// ): Executor.Model[] =>
// 	useMemo(() => {
// 		const canNotShow = true;
// 		const canShowAndOnNextFilter = false;

// 		type typeIdOrNot = number | undefined;
// 		// type typeId = number | string;
// 		// type typeArrId = (number | string)[];
// 		type typeId = number | "all";
// 		type typeArrId = (number | "all")[];

// 		const isNotArr = (arr?: typeArrId) => !Array.isArray(arr);

// 		const isAll = (arr?: typeArrId) =>
// 			Array.isArray(arr) && arr.length === 1 && arr[0] === "all";

// 		const isEmptyArr = (arr?: typeArrId) =>
// 			Array.isArray(arr) && arr.length === 0;

// 		const isNumber = (id: typeIdOrNot) =>
// 			typeof id === "number" && !isNaN(id);

// 		const isCanSkipFilter = (arr?: typeArrId) =>
// 			isNotArr(arr) || isAll(arr) || isEmptyArr(arr);

// 		const toStr = (id: typeId) => id.toString();

// 		const filterByIds = (id: typeIdOrNot, filterIds: typeArrId) => {
// 			if (typeof id !== "number" || isNaN(id)) return canNotShow;
// 			return filterIds.some((filterId) => toStr(filterId) === toStr(id));
// 		};

// 		const checkCompanyId = (
// 			// Company
// 			onFilter: boolean,
// 			companyId: typeIdOrNot,
// 			companyIds: typeArrId,
// 		): boolean => {
// 			if (!onFilter || isCanSkipFilter(companyIds))
// 				return canShowAndOnNextFilter;

// 			const resultThisFilter = !filterByIds(companyId, companyIds || []);

// 			return resultThisFilter;
// 		};

// 		const checkTaxiServiceId = (
// 			// Fils
// 			onFilter: boolean,
// 			taxiServiceId: typeIdOrNot,
// 			taxiServiceIds: typeArrId,
// 		): boolean => {
// 			if (!onFilter || isCanSkipFilter(taxiServiceIds))
// 				return canShowAndOnNextFilter;

// 			const resultThisFilter = !filterByIds(
// 				taxiServiceId,
// 				taxiServiceIds || [],
// 			);

// 			return resultThisFilter;
// 		};

// 		const checkExecutorId = (
// 			// Executors
// 			onFilter: boolean,
// 			executorId: typeId,
// 			executorIds: typeArrId,
// 		): boolean => {
// 			if (!onFilter || isCanSkipFilter(executorIds))
// 				return canShowAndOnNextFilter;

// 			const resultThisFilter = !executorIds.includes(executorId);

// 			return resultThisFilter;
// 		};

// 		const checkExecutorGroupId = (
// 			// Vehicle fleets
// 			onFilter: boolean,
// 			groupId: typeIdOrNot,
// 			executorGroupIds: typeArrId,
// 		): boolean => {
// 			if (!onFilter || isCanSkipFilter(executorGroupIds))
// 				return canShowAndOnNextFilter;

// 			const resultThisFilter = !filterByIds(
// 				groupId,
// 				executorGroupIds || [],
// 			);

// 			return resultThisFilter;
// 		};

// 		const checkFleetId = (
// 			// Brigade
// 			onFilter: boolean,
// 			fleetId: typeIdOrNot,
// 			fleetIds: typeArrId,
// 		): boolean => {
// 			if (!onFilter || isCanSkipFilter(fleetIds))
// 				return canShowAndOnNextFilter;

// 			const resultThisFilter = !filterByIds(fleetId, fleetIds || []);

// 			return resultThisFilter;
// 		};

// 		const defaultActiveFilters = {
// 			companyIds: false,
// 			taxiServiceIds: false,
// 			executorIds: false,
// 			executorGroupIds: false,
// 			fleetIds: false,
// 		};

// 		const mergedActiveFilters = {
// 			...defaultActiveFilters,
// 			...activeFilters,
// 		};

// 		const {
// 			executorIds,
// 			taxiServiceIds,
// 			companyIds,
// 			executorGroupIds,
// 			fleetIds,
// 		} = filters;

// 		const retval = executors.filter((executor) => {
// 			const { id, taxiService, group, currentParking } = executor;
// 			const company = taxiService?.company;
// 			const parking = currentParking?.parking;

// 			if (
// 				checkCompanyId(
// 					mergedActiveFilters.companyIds,
// 					company?.id,
// 					companyIds,
// 				) ||
// 				checkTaxiServiceId(
// 					mergedActiveFilters.taxiServiceIds,
// 					taxiService?.id,
// 					taxiServiceIds,
// 				) ||
// 				checkExecutorId(
// 					mergedActiveFilters.executorIds,
// 					id,
// 					executorIds,
// 				) ||
// 				checkExecutorGroupId(
// 					mergedActiveFilters.executorGroupIds,
// 					group?.id,
// 					executorGroupIds,
// 				) ||
// 				checkFleetId(
// 					mergedActiveFilters.fleetIds,
// 					currentParking?.id,
// 					fleetIds,
// 				)
// 			) {
// 				return false;
// 			}

// 			// if (
// 			// 	(mergedActiveFilters.companyIds &&
// 			// 		checkCompanyId(company?.id, companyIds)) ||
// 			// 	(mergedActiveFilters.taxiServiceIds &&
// 			// 		checkTaxiServiceId(taxiService?.id, taxiServiceIds)) ||
// 			// 	(mergedActiveFilters.executorIds &&
// 			// 		checkExecutorId(id, executorIds)) ||
// 			// 	(mergedActiveFilters.executorGroupIds &&
// 			// 		checkExecutorGroupId(group?.id, executorGroupIds)) ||
// 			// 	(mergedActiveFilters.fleetIds &&
// 			// 		checkFleetId(parking?.id, fleetIds))
// 			// ) {
// 			// 	return false;
// 			// }

// 			return true;
// 		});

// 		console.group("Console_Group_DP99");
// 		console.log(`executors`, executors);
// 		console.log(`-------------------`);
// 		console.log(`filters`, filters);
// 		console.log(`activeFilters`, activeFilters);
// 		console.log(`-------------------`);

// 		console.log(`retval`, retval);

// 		//  console.log(`activeKey`, activeTab);
// 		//	console.table(mergedActiveFilters);
// 		console.groupEnd();

// 		return retval;
// 	}, [activeFilters, executors, filters]);

// export default useFilteredExecutors;

// import { useMemo } from "react";
// import { isUndefined, isNaN } from "lodash";

// import { Executor } from "../../../services";

// type Nullable<T> = T | undefined;
// type FilterId = number | "all";
// type FilterIdArray = FilterId[];

// type Filters = {
// 	companyIds?: FilterIdArray;
// 	taxiServiceIds?: FilterIdArray;
// 	executorIds?: FilterIdArray;
// 	executorGroupIds?: FilterIdArray;
// 	fleetIds?: FilterIdArray;
// };

// type ActiveFilters = Partial<Record<keyof Filters, boolean>>;

// const DEFAULT_FILTERS: Filters = {
// 	companyIds: ["all"],
// 	taxiServiceIds: ["all"],
// 	executorIds: ["all"],
// 	executorGroupIds: ["all"],
// 	fleetIds: ["all"],
// };

// const DEFAULT_ACTIVE_FILTERS: ActiveFilters = {
// 	companyIds: false,
// 	taxiServiceIds: false,
// 	executorIds: false,
// 	executorGroupIds: false,
// 	fleetIds: false,
// };

// const useFilteredExecutors = (
// 	executors: Executor.Model[],
// 	filters: Filters = DEFAULT_FILTERS,
// 	activeFilters: ActiveFilters = DEFAULT_ACTIVE_FILTERS,
// ): Executor.Model[] =>
// 	useMemo(() => {
// 		const shouldSkipFilter = (arr?: FilterIdArray) =>
// 			!Array.isArray(arr) ||
// 			arr.length === 0 ||
// 			(arr.length === 1 && arr[0] === "all");

// 		const filterByIds = (id: Nullable<number>, filterIds: FilterIdArray) =>
// 			typeof id === "number" &&
// 			!isNaN(id) &&
// 			filterIds.some((filterId) => filterId.toString() === id.toString());

// 		const checkFilter = (
// 			isActive: boolean,
// 			id: Nullable<number>,
// 			filterIds: FilterIdArray,
// 		): boolean =>
// 			isActive && !shouldSkipFilter(filterIds)
// 				? !filterByIds(id, filterIds)
// 				: false;

// 		const mergedActiveFilters = {
// 			...DEFAULT_ACTIVE_FILTERS,
// 			...activeFilters,
// 		};
// 		// const mergedFilters = { ...DEFAULT_FILTERS, ...filters };

// 		const mergedFilters = {
// 			companyIds: filters?.companyIds ?? DEFAULT_FILTERS.companyIds ?? [],
// 			taxiServiceIds:
// 				filters?.taxiServiceIds ?? DEFAULT_FILTERS.taxiServiceIds ?? [],
// 			executorIds:
// 				filters?.executorIds ?? DEFAULT_FILTERS.executorIds ?? [],
// 			executorGroupIds:
// 				filters?.executorGroupIds ??
// 				DEFAULT_FILTERS.executorGroupIds ??
// 				[],
// 			fleetIds: filters?.fleetIds ?? DEFAULT_FILTERS.fleetIds ?? [],
// 		};

// 		const retval = executors.filter(
// 			({ id, taxiService, group, currentParking }) => {
// 				const companyId = taxiService?.company?.id;
// 				const groupId = group?.id;
// 				const fleetId = currentParking?.id;

// 				return !(
// 					checkFilter(
// 						!!mergedActiveFilters.companyIds,
// 						companyId,
// 						mergedFilters.companyIds,
// 					) ||
// 					checkFilter(
// 						!!mergedActiveFilters.taxiServiceIds,
// 						taxiService?.id,
// 						mergedFilters.taxiServiceIds,
// 					) ||
// 					checkFilter(
// 						!!mergedActiveFilters.executorIds,
// 						id,
// 						mergedFilters.executorIds,
// 					) ||
// 					checkFilter(
// 						!!mergedActiveFilters.executorGroupIds,
// 						groupId,
// 						mergedFilters.executorGroupIds,
// 					) ||
// 					checkFilter(
// 						!!mergedActiveFilters.fleetIds,
// 						fleetId,
// 						mergedFilters.fleetIds,
// 					)
// 				);
// 			},
// 		);

// 		console.group("Console_Group_DP99 Filtered Executors Debug");
// 		console.log("Executors:", executors);
// 		console.log("Filters:", filters);
// 		console.log("Active Filters:", activeFilters);
// 		console.log("Result:", retval);
// 		console.groupEnd();

// 		return retval;
// 	}, [activeFilters, executors, filters]);

// export default useFilteredExecutors;

import { useMemo } from "react";
import { isUndefined, isNaN } from "lodash";

import { Executor } from "../../../services";
import { useCarParkFilterAccess } from "../../../access";
import { useCarParkIdsDecoder } from "../../../hooks";

type Nullable<T> = T | undefined;
type FilterId = number | "all";
type FilterIdArray = FilterId[];

type Filters = {
	companyIds?: FilterIdArray;
	taxiServiceIds?: FilterIdArray;
	executorIds?: FilterIdArray;
	executorGroupIds?: FilterIdArray;
	fleetIds?: FilterIdArray;
};

type ActiveFilters = Partial<Record<keyof Filters, boolean>>;

const DEFAULT_FILTERS: Filters = {
	companyIds: ["all"],
	taxiServiceIds: ["all"],
	executorIds: ["all"],
	executorGroupIds: ["all"],
	fleetIds: ["all"],
};

const DEFAULT_ACTIVE_FILTERS: ActiveFilters = {
	companyIds: false,
	taxiServiceIds: false,
	executorIds: false,
	executorGroupIds: false,
	fleetIds: false,
};

const useFilteredExecutors = (
	executors: Executor.Model[],
	filters: Filters = DEFAULT_FILTERS,
	activeFilters: ActiveFilters = DEFAULT_ACTIVE_FILTERS,
): Executor.Model[] => {
	const { isUserFleetOwner } = useCarParkFilterAccess<any>();
	const allCarParkIds = useCarParkIdsDecoder();
	const fleetIdsAll = useMemo(() => allCarParkIds(["all"]), [allCarParkIds]);

	return useMemo(() => {
		const shouldSkipStrictFilter = (arr?: FilterIdArray) =>
			!Array.isArray(arr) ||
			arr.length === 0 ||
			(arr.length === 1 && arr[0] === "all");

		const hasCommonElement = (arr1: FilterIdArray, arr2: FilterIdArray) =>
			arr1.some((id) => arr2.includes(id));

		const filterByIds = (id: Nullable<number>, filterIds: FilterIdArray) =>
			typeof id === "number" &&
			!isNaN(id) &&
			filterIds.some((filterId) => filterId.toString() === id.toString());

		const checkStrictFilter = (
			isActive: boolean,
			id: Nullable<number>,
			filterIds: FilterIdArray,
		): boolean =>
			isActive && !shouldSkipStrictFilter(filterIds)
				? !filterByIds(id, filterIds)
				: false;

		const checkSoftFilter = (
			isActive: boolean,
			id: Nullable<number>,
			filterIds: FilterIdArray,
		): boolean => {
			const isEmptyArr =
				isActive && Array.isArray(filterIds) && filterIds.length === 0;
			if (isEmptyArr) return false;

			return isActive && !shouldSkipStrictFilter(filterIds)
				? !filterByIds(id, filterIds)
				: false;
		};

		const checkSoftArrFilter = (
			isActive: boolean,
			ids: FilterIdArray,
			filterIds: FilterIdArray,
		): boolean => {
			const isEmptyArr =
				isActive && Array.isArray(filterIds) && filterIds.length === 0;
			if (isEmptyArr) return isUserFleetOwner;

			const decoderIds =
				isUserFleetOwner && filterIds?.[0] === "all"
					? fleetIdsAll
					: filterIds;

			return isActive && !shouldSkipStrictFilter(decoderIds)
				? !hasCommonElement(ids, decoderIds)
				: false;
		};

		const mergedActiveFilters = {
			...DEFAULT_ACTIVE_FILTERS,
			...activeFilters,
		};
		const mergedFilters = {
			companyIds: filters?.companyIds ?? [],
			taxiServiceIds: filters?.taxiServiceIds ?? [],
			executorIds: filters?.executorIds ?? [],
			executorGroupIds: filters?.executorGroupIds ?? [],
			fleetIds: filters?.fleetIds ?? [],
		};

		const retval = executors.filter(
			({ id, taxiService, group, currentParking, cars }) => {
				const companyId = taxiService?.company?.id;
				const groupId = group?.id;
				// const fleetId = currentParking?.id;
				const fleetIds = cars?.map((car) => car.park?.id ?? -2) ?? [];

				return !(
					checkStrictFilter(
						!!mergedActiveFilters.companyIds,
						companyId,
						mergedFilters.companyIds,
					) ||
					checkStrictFilter(
						!!mergedActiveFilters.taxiServiceIds,
						taxiService?.id,
						mergedFilters.taxiServiceIds,
					) ||
					checkStrictFilter(
						!!mergedActiveFilters.executorIds,
						id,
						mergedFilters.executorIds,
					) ||
					checkSoftFilter(
						!!mergedActiveFilters.executorGroupIds,
						groupId,
						mergedFilters.executorGroupIds,
					) ||
					checkSoftArrFilter(
						!!mergedActiveFilters.fleetIds,
						fleetIds,
						mergedFilters.fleetIds,
					)
				);
			},
		);

		return retval;
	}, [activeFilters, executors, filters, fleetIdsAll, isUserFleetOwner]);
};

export default useFilteredExecutors;
