import { useCallback, useMemo } from "react";

import { Company } from "../../services";
import { useTypedSelector } from "../../redux/store";
import { useTaxiServiceFilterAccess } from "../../access";

// function isAllIds(
// 	taxiServices: useCompanyIdsDecoder.EncodedIds,
// ): taxiServices is ["all"] {
// 	return taxiServices[0] === "all";
// }

function useCompanyIdsDecoder() {
	const { accessCompaniesIds } = useTaxiServiceFilterAccess<any>();

	const { companies: companiesData } = useTypedSelector(
		(state) => state.ordersPageReducer,
	);

	const companies: Company.Model[] = useMemo(
		() => companiesData?.items ?? [],
		[companiesData?.items],
	);

	const availableCompanyIds = useMemo(
		() => companies.map((company) => company.id),
		[companies],
	);

	const isAllIds = useCallback(
		(
			taxiServices: useCompanyIdsDecoder.EncodedIds,
		): taxiServices is ["all"] => taxiServices[0] === "all",
		[],
	);

	const decoder = useCallback(
		(
			companyIds: useCompanyIdsDecoder.EncodedIds,
			options: useCompanyIdsDecoder.DecoderOptions = {},
		) => {
			const isOnlyAccessIds = options.isOnlyAccess
				? accessCompaniesIds
				: availableCompanyIds;
			if (isAllIds(companyIds)) return isOnlyAccessIds;

			return isOnlyAccessIds.filter((companyId) =>
				companyIds.includes(companyId),
			);
		},
		[accessCompaniesIds, availableCompanyIds, isAllIds],
	);

	return decoder;
}

declare namespace useCompanyIdsDecoder {
	type EncodedIds = number[] | ["all"];

	interface DecoderOptions {
		isOnlyAccess?: boolean;
	}
}

export default useCompanyIdsDecoder;
