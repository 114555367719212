/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import React, { Dispatch, RefAttributes, memo, useMemo, useState } from "react";
import { Column, react } from "uikit";
import { noop } from "lodash";

import { Executor, Language } from "../../services";
import useObjectEditor from "../../hooks/useObjectEditor";
import { useExecutorsSubHandler, useTaxiServiceIdsDecoder } from "../../hooks";

import Root from "./components/Root";
import InternalController from "./Controller";
import ModelTable from "./components/ModelTable";

const ExecutorSelectTab = memo(
	react.withController<
		ExecutorSelectTab.PropsBase,
		ExecutorSelectTab.Controller
	>(
		({
			value,
			disabled,
			disabledList,
			visible,
			language,
			allowedTaxiServiceIds,
			onChange,
		}) => {
			const { getAllExecutorsSubHandler } = useExecutorsSubHandler();
			const valueEditor = useObjectEditor(value, onChange);

			const [filters, setFilters] = useState<ModelTable.Props["filters"]>(
				{
					companyIds: ["all"],
					taxiServiceIds: ["all"],
					search: "",
				},
			);

			const decodeTaxiServiceIds = useTaxiServiceIdsDecoder({
				allowedTaxiServiceIds,
			});

			const [sort, setSort] = useState<ModelTable.Sort>({});

			const items = useMemo(() => {
				const options: Executor.SubscribeOptions = {
					taxiServiceIds: decodeTaxiServiceIds(
						filters.companyIds,
						filters.taxiServiceIds,
					),
					query: filters.search,
				};

				if (sort.column && sort.type)
					options.order = {
						[sort.column as keyof Executor.SubscribeOptions["order"]]:
							sort.type,
					};

				return getAllExecutorsSubHandler(options);
			}, [
				decodeTaxiServiceIds,
				filters.companyIds,
				filters.search,
				filters.taxiServiceIds,
				getAllExecutorsSubHandler,
				sort.column,
				sort.type,
			]);

			const tableOnChangeSelected = valueEditor.useSetter("executorIds");

			return (
				<Root hasPaddings={false} visible={visible}>
					<Column sizes="1fr" maxedWidth maxedHeight>
						<ModelTable
							filters={filters}
							selected={valueEditor.get("executorIds")}
							sort={sort}
							disabled={disabled}
							disabledList={disabledList}
							loading={false}
							data={items}
							language={language}
							allowedTaxiServiceIds={allowedTaxiServiceIds}
							onChangeFilters={setFilters}
							onChangeSelected={tableOnChangeSelected}
							onChangeSort={setSort}
							onLoadMore={noop}
						/>
					</Column>
				</Root>
			);
		},
		InternalController,
	),
);

declare namespace ExecutorSelectTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		executorIds: number[];
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		/** A list of executors, that should be disable. */
		disabledList?: number[];

		visible: boolean;
		language: Language;

		allowedTaxiServiceIds: number[];

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default ExecutorSelectTab;
