import React, { useEffect, useMemo, Fragment } from "react";
import { isEqual } from "lodash";
import { useDispatch } from "react-redux";

import Executor from "../../../../../../../../services/Executor";
import orderPageFilters, {
	ExecutorFilter,
} from "../../../../../../../../redux/reducers/OrdersPage/Filters";
import { useTypedSelector } from "../../../../../../../../redux/store";
import { ordersAction as orders } from "../../../../../../../../redux/reducers/Orders";
import {
	useExecutorsSubHandler,
	useCompanyIdsDecoder,
	useTaxiServiceIdsDecoder,
} from "../../../../../../../../hooks";

import { ChatsSubscribe, ExecutorsSubscribe } from "./components";

const SubscribeProvider: React.FC = () => {
	const dispatch = useDispatch();

	const allCompanyIds = useCompanyIdsDecoder();
	const allTaxiServiceIds = useTaxiServiceIdsDecoder();
	const { getAllExecutorsSubHandler } = useExecutorsSubHandler();

	const taxiServiceIds = useMemo(
		() => allTaxiServiceIds(["all"], ["all"]),
		[allTaxiServiceIds],
	);
	const companyIds = useMemo(() => allCompanyIds(["all"]), [allCompanyIds]);

	const defaultFilter = useMemo<ExecutorFilter>(
		() => ({
			companyIds: allCompanyIds(["all"]) ?? [],
			taxiServiceIds: allTaxiServiceIds(["all"], ["all"]) ?? [],
			executorGroupIds: [],
			fleetIds: [],
			statuses: [
				"available",
				"busy",
				"dinner",
				"home",
				"on_order",
				"closed",
			],
			online: ["online", "offline"],
			workingStatus: ["working", "notWorking"],
			isYourOrderStatus: true,
			order: {
				column: undefined,
				type: undefined,
			},
			query: "",
		}),
		[allCompanyIds, allTaxiServiceIds],
	);

	const { chats } = useTypedSelector((state) => state.orderPageFilters);

	const { criteria: executors } = useTypedSelector(
		(state) => state.orders.executors.filter,
	);
	const { criteria: parkings } = useTypedSelector(
		(state) => state.orders.parking.filter,
	);

	const createOrderObject = (column: string, type: string) => ({
		[column]: type,
	});

	const modelExecutorSubscribe = useMemo(() => {
		const optionsExecutor: Executor.SubscribeOptions =
			chats.filters.order?.column && chats.filters.order?.type
				? {
						order: createOrderObject(
							chats.filters.order.column,
							chats.filters.order.type,
						),
				  }
				: {};

		return getAllExecutorsSubHandler(optionsExecutor);
	}, [
		chats.filters.order?.column,
		chats.filters.order?.type,
		getAllExecutorsSubHandler,
	]);

	const shouldSubscribeToChat = useMemo(() => {
		if (chats.filters.query !== defaultFilter.query) return true;
		return false;
	}, [chats.filters.query, defaultFilter.query]);

	const shouldSubscribeToExecutors = useMemo(() => {
		if (!isEqual(executors, defaultFilter)) return true;
		return false;
	}, [defaultFilter, executors]);

	useEffect(() => {
		const shouldUpdateExecutorsFilter =
			!executors.companyIds.length || !executors.taxiServiceIds;
		const shouldUpdateParkingsFilter =
			!parkings.companyIds.length || !parkings.taxiServiceIds;
		const shouldDefaultFilter =
			!defaultFilter.companyIds.length || !defaultFilter.taxiServiceIds;

		if (shouldUpdateExecutorsFilter) {
			dispatch(orders.executors.filter.setExecutorsFilter(defaultFilter));
		}
		if (shouldUpdateParkingsFilter) {
			// dispatch(
			// 	orderPageFilters.actions.setParkingsFilter({
			// 		...parkings,
			// 		companyIds,
			// 		taxiServiceIds,
			// 	}),
			// );W

			dispatch(
				orders.parking.filter.setParkingsFilter({
					...parkings,
					companyIds,
					taxiServiceIds,
				}),
			);
		}
		if (shouldDefaultFilter) {
			dispatch(
				orderPageFilters.actions.setDefaultFilter({
					...defaultFilter,
					companyIds,
					taxiServiceIds,
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companyIds, taxiServiceIds, defaultFilter, dispatch]);

	useEffect(() => {
		if (!shouldSubscribeToExecutors) {
			dispatch(
				orders.executors.data.setExecutorsData(modelExecutorSubscribe),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modelExecutorSubscribe, shouldSubscribeToExecutors]);

	useEffect(() => {
		dispatch(orders.parking.data.setParkingsData(modelExecutorSubscribe));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modelExecutorSubscribe]);

	useEffect(() => {
		if (!shouldSubscribeToChat) {
			dispatch(
				orders.chats.chat.setExecutorsInUserModalData(
					modelExecutorSubscribe,
				),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modelExecutorSubscribe, shouldSubscribeToChat]);

	return (
		<Fragment>
			{shouldSubscribeToChat && <ChatsSubscribe />}

			<ExecutorsSubscribe />
		</Fragment>
	);
};

export default SubscribeProvider;
