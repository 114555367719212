/* eslint-disable consistent-return */
import React, { useCallback, useEffect, useState } from "react";
import { Button, Float, Icon, Popover as PopoverBase, Row } from "uikit";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";

import Language from "../../../../../../../services/Language";
import { ExecutorStatus } from "../../../../../../../services/Executor";
import { useTypedSelector } from "../../../../../../../redux/store";
import { Orders } from "../../../../../../../redux/reducers/Orders";

import Content from "./components/Content";
import Header from "./components/Header";
import Root from "./components/Root";

function additionalFiltersOffsetCallback({
	tracker,
	float,
}: Float.OffsetCallbackOptions) {
	if (!float || !tracker) return { x: 0, y: 0 };

	const trackerSize = tracker.getSize();

	// TODO: fix useSize in Float/index.tsx, idk
	const floatSize = { width: 620, height: 242 }; // float.getSize();

	return {
		x: -Number(floatSize.width) + Number(trackerSize.width) + 13,
		y: Number(trackerSize.height) + 13,
	};
}

const Popover: React.FC<Popover.Props> = ({
	language,
	open,
	onClose,
	filter,
	setFilter,
	setShowModalPropsForExecutorGroup,
	setShowModalPropsForCarPark,
}) => {
	const { t } = useTranslation();

	const { criteria } = useTypedSelector(
		(state) => state.orders.executors.filter,
	);

	const currentFilters = omit(criteria, ["order", "query"]);

	const [companyAndTaxiServiceError, setCompanyAndTaxiServiceError] =
		useState({
			taxiService: false,
			company: false,
		});

	const clearErrors = useCallback(() => {
		setCompanyAndTaxiServiceError({ company: false, taxiService: false });
	}, []);

	const baseFilter: Orders.Executors.ExecuterFilter = {
		statuses: [],
		taxiServiceIds: [],
		companyIds: [],
		online: [],
		workingStatus: [],
		isYourOrderStatus: false,
		fleetIds: ["all"],
		executorGroupIds: [],
	};

	const [requestData, setRequestData] =
		useState<Orders.Executors.ExecuterFilter>(currentFilters ?? baseFilter);

	useEffect(() => {
		// if (criteria.fleetIds) {
		// 	setFleetIds(criteria.fleetIds);
		// }
		if (criteria.executorGroupIds) {
			setExecutorGroupIds(criteria.executorGroupIds);
		}
	}, [criteria.fleetIds, criteria.executorGroupIds]);

	const [isYourOrderStatus, setIsYourOrderStatus] = useState(
		filter.isYourOrderStatus,
	);

	const clearFilters = useCallback(() => {
		setCompanyAndTaxiServiceIds({
			companyIds: ["all"],
			taxiServiceIds: ["all"],
		});
		setFleetIds(["all"]);
		setExecutorGroupIds([]);
		setExecutorsStatuses([
			"available",
			"busy",
			"dinner",
			"home",
			"on_order",
			"closed",
		]);
		setOnlineStatus(["online", "offline"]);
		setWorkingStatus(["working", "notWorking"]);
		setIsYourOrderStatus(true);
		clearErrors();
	}, [clearErrors]);

	const updateFilters = useCallback(() => {
		setCompanyAndTaxiServiceIds({
			companyIds: filter.companyIds,
			taxiServiceIds: filter.taxiServiceIds,
		});
		setExecutorsStatuses(filter.statuses as ExecutorStatus[]);
		setOnlineStatus(filter.online);
		setWorkingStatus(filter.workingStatus);
		setIsYourOrderStatus(filter.isYourOrderStatus);
		clearErrors();
	}, [
		clearErrors,
		filter.companyIds,
		filter.isYourOrderStatus,
		filter.online,
		filter.statuses,
		filter.taxiServiceIds,
		filter.workingStatus,
	]);

	const apply = useCallback(() => {
		if (!requestData.taxiServiceIds.length)
			companyAndTaxiServiceError.taxiService = true;
		if (!requestData.companyIds.length)
			companyAndTaxiServiceError.company = true;
		if (
			!requestData.taxiServiceIds.length ||
			!requestData.companyIds.length
		)
			return setCompanyAndTaxiServiceError(() => ({
				...companyAndTaxiServiceError,
			}));
		setFilter({
			...requestData,
			query: filter.query,
			order: filter.order,
		});
		setShowCompany(false);
		setShowTaxiService(false);
		onClose();
		clearErrors();
	}, [
		clearErrors,
		companyAndTaxiServiceError,
		filter.order,
		filter.query,
		onClose,
		requestData,
		setFilter,
	]);

	const cancel = useCallback(() => {
		updateFilters();
		setShowCompany(false);
		setShowTaxiService(false);
		onClose();
		clearErrors();
	}, [clearErrors, onClose, updateFilters]);

	const [onlineStatus, setOnlineStatus] = useState(filter.online);

	const [showCompany, setShowCompany] = useState(false);
	const [showTaxiService, setShowTaxiService] = useState(false);

	const [companyAndTaxiServiceIds, setCompanyAndTaxiServiceIds] =
		useState<Filter>({
			companyIds: filter.companyIds,
			taxiServiceIds: filter.taxiServiceIds,
		});
	const [executorsStatuses, setExecutorsStatuses] = useState<
		Content.ExecutorStatus[]
	>(filter.statuses as Content.ExecutorStatus[]);

	const [workingStatus, setWorkingStatus] = useState(filter.workingStatus);

	const [fleetIds, setFleetIds] = useState<number[] | ["all"]>(["all"]);
	const [executorGroupIds, setExecutorGroupIds] = useState<number[]>([]);

	useEffect(() => {
		setCompanyAndTaxiServiceIds({
			companyIds: filter.companyIds,
			taxiServiceIds: filter.taxiServiceIds,
		});
	}, [filter.companyIds, filter.taxiServiceIds]);

	return (
		<PopoverBase
			open={open}
			containerId="root"
			trackerId="filters"
			offset={additionalFiltersOffsetCallback}
			onClose={onClose}
			style={{ boxShadow: "rgba(0,0,0,0.2) 0px 6px 24px 0px" }}
		>
			<Root align="stretch" gaps="16px 20px">
				<Header onClose={onClose} />

				<Content
					language={language}
					requestData={requestData}
					onChangeRequestData={setRequestData}
					showCompany={showCompany}
					setShowCompany={setShowCompany}
					showTaxiService={showTaxiService}
					setShowTaxiService={setShowTaxiService}
					companyAndTaxiServiceIds={companyAndTaxiServiceIds}
					setCompanyAndTaxiServiceIds={setCompanyAndTaxiServiceIds}
					executorsStatuses={executorsStatuses}
					setExecutorsStatuses={setExecutorsStatuses}
					onlineStatus={onlineStatus}
					setOnlineStatus={setOnlineStatus}
					workingStatus={workingStatus}
					setWorkingStatus={setWorkingStatus}
					error={companyAndTaxiServiceError}
					onChangeError={setCompanyAndTaxiServiceError}
					isYourOrderStatus={isYourOrderStatus}
					setIsYourOrderStatus={setIsYourOrderStatus}
					fleetIds={fleetIds}
					setFleetIds={setFleetIds}
					executorGroupIds={executorGroupIds}
					setExecutorGroupIds={setExecutorGroupIds}
					setShowModalPropsForExecutorGroup={
						setShowModalPropsForExecutorGroup
					}
					setShowModalPropsForCarPark={setShowModalPropsForCarPark}
				/>

				<Row justify="space-between">
					<Button.Button
						icon={<Icon size={18} id="refresh" />}
						variant="secondary"
						onClick={clearFilters}
					/>
					<Row gaps="10px">
						<Button.Button
							variant="secondary"
							text={t("cancel") || "Cancel"}
							onClick={cancel}
						/>
						<Button.Button
							text={
								t(
									"orderPageWidgets.executors.filter.popover.str0",
								) ?? ""
							}
							onClick={apply}
						/>
					</Row>
				</Row>
			</Root>
		</PopoverBase>
	);
};

declare namespace Popover {
	interface Props {
		open: boolean;
		language: Language;
		filter: Orders.Executors.ExecuterFilter;
		setFilter: (executorFilter: Orders.Executors.ExecuterFilter) => void;

		onClose: () => void;

		setShowModalPropsForExecutorGroup: (value: boolean) => void;
		setShowModalPropsForCarPark: (value: boolean) => void;
	}
}

export interface Filter {
	companyIds: number[] | ["all"];
	taxiServiceIds: number[] | ["all"];
}

export default Popover;
