import { useCallback, useMemo } from "react";

import { Company } from "../../services";
import { useTypedSelector } from "../../redux/store";
import { useTaxiServiceFilterAccess } from "../../access";

function useExecutorIdsDecoder() {
	// const { accessIds } = useTaxiServiceFilterAccess<any>();

	const executors =
		useTypedSelector(
			(state) => state.globalState.executors.data.subscription.basic,
		) || [];

	const availableIds = useMemo(
		() => executors.map((company) => company.id),
		[executors],
	);

	const isAllIds = useCallback(
		(
			taxiServices: useExecutorIdsDecoder.EncodedIds,
		): taxiServices is ["all"] => taxiServices[0] === "all",
		[],
	);

	const decoder = useCallback(
		(
			companyIds: useExecutorIdsDecoder.EncodedIds,
			options: useExecutorIdsDecoder.DecoderOptions = {},
		) => {
			const isOnlyAccessIds = /* options.isOnlyAccess
				? accessIds
				: */ availableIds;
			if (isAllIds(companyIds)) return isOnlyAccessIds;

			return isOnlyAccessIds.filter((companyId) =>
				companyIds.includes(companyId),
			);
		},
		[availableIds, isAllIds],
	);

	return decoder;
}

declare namespace useExecutorIdsDecoder {
	type EncodedIds = number[] | ["all"];

	interface DecoderOptions {
		// isOnlyAccess?: boolean;
	}
}

export default useExecutorIdsDecoder;
