import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Icon, Float } from "uikit";
import { useDispatch } from "react-redux";
import { isEqual, omit } from "lodash";

import { Language } from "../../../../../services";
import { useTypedSelector } from "../../../../../redux/store";
import {
	ordersAction as orders,
	Orders,
} from "../../../../../redux/reducers/Orders";
import { baseFilters } from "../../../../../redux/reducers/Orders/Executors/filter";
import {
	useCompanyIdsDecoder,
	useTaxiServiceIdsDecoder,
	useCarParkIdsDecoder,
} from "../../../../../hooks";

import Popover from "./components/Popover";

interface Props {
	language: Language;
}

const Filter: React.FC<Props> = ({ language }) => {
	const dispatch = useDispatch();

	const { criteria } = useTypedSelector(
		(state) => state.orders.executors.filter,
	);

	const allTaxiServiceIds = useTaxiServiceIdsDecoder();
	const allCompanyIds = useCompanyIdsDecoder();
	const allCarParkIds = useCarParkIdsDecoder();

	const taxiServiceIds = useMemo(
		() => allTaxiServiceIds(["all"], ["all"], { isOnlyAccess: true }),
		[allTaxiServiceIds],
	);
	const companyIds = useMemo(
		() => allCompanyIds(["all"], { isOnlyAccess: true }),
		[allCompanyIds],
	);

	const fleetIdsAll = useMemo(() => allCarParkIds(["all"]), [allCarParkIds]);

	const defaultFilters = omit(baseFilters, ["order"]);

	const defaultFilter = useMemo<Orders.Executors.ExecuterFilter>(
		() => ({ ...defaultFilters, companyIds, taxiServiceIds }),
		[companyIds, taxiServiceIds, defaultFilters],
	);

	const isFilterActive = useMemo(() => {
		const currentFilters = omit(criteria, ["order"]);
		const retval = !isEqual(currentFilters, defaultFilter);

		return retval;
	}, [criteria, defaultFilter]);

	const onChangeFilter = useCallback(
		(executorFilter: Orders.Executors.ExecuterFilter) => {
			dispatch(
				orders.executors.filter.setExecutorsFilter(executorFilter),
			);
		},
		[dispatch],
	);

	const [isButtonLocked, setIsButtonLocked] = useState(false);
	const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);
	const [showModalPropsForExecutorGroup, setShowModalPropsForExecutorGroup] =
		useState<boolean>(false);
	const [showModalPropsForCarPark, setShowModalPropsForCarPark] =
		useState<boolean>(false);

	const isActiveShowMultiModal =
		showModalPropsForExecutorGroup || showModalPropsForCarPark;

	const buttonOnClick = useCallback(() => {
		openAdditionalFilters
			? undefined
			: !isButtonLocked && setOpenAdditionalFilters(true);
	}, [isButtonLocked, openAdditionalFilters]);

	const buttonOnMouseDown = useCallback(
		(e) => {
			e.preventDefault();
			if (openAdditionalFilters) setIsButtonLocked(true);
		},
		[openAdditionalFilters],
	);

	const popoverOnClose = useCallback(() => {
		if (!isActiveShowMultiModal) setOpenAdditionalFilters(false);
	}, [isActiveShowMultiModal]);

	useEffect(() => {
		const unblockHandler = () => {
			setIsButtonLocked(false);
		};

		document.addEventListener("click", unblockHandler);

		return () => {
			document.removeEventListener("click", unblockHandler);
		};
	}, []);

	return (
		<>
			<Float.Tracker id="filters">
				<Button.Button
					style={
						isFilterActive
							? { backgroundColor: "rgb(109,190,255)" }
							: {}
					}
					variant={isFilterActive ? "primary" : "secondary"}
					icon={<Icon id="filters" size={20} />}
					onClick={buttonOnClick}
					onMouseDown={buttonOnMouseDown}
				/>
			</Float.Tracker>

			<Popover
				filter={criteria}
				setFilter={onChangeFilter}
				language={language}
				open={openAdditionalFilters}
				onClose={popoverOnClose}
				setShowModalPropsForExecutorGroup={
					setShowModalPropsForExecutorGroup
				}
				setShowModalPropsForCarPark={setShowModalPropsForCarPark}
			/>
		</>
	);
};

export default Filter;
