import React, { useState } from "react";
import styled from "styled-components";

import { useTypedSelector } from "../../../../../redux/store";
import { Orders } from "../../../../../redux/reducers/Orders";
import { baseFilters } from "../../../../../redux/reducers/Orders/Chat/filter";
import { ExecutorsFilterPanel } from "..";

const Root = styled.div`
	right: 0;
	padding: 0;
`;

const Filter: React.FC<Filter.Props> = (props) => {
	const { initFilter, saveFilterOnRedux } = props;
	const { criteria } = initFilter;

	const lang = useTypedSelector((state) => state.session.language);

	const [filters, setFilters] = useState<Orders.Chat.ModalFilter>(
		criteria ?? baseFilters,
	);

	const handleFilters = (data: Orders.Chat.ModalFilter) => {
		saveFilterOnRedux(data);
	};

	return (
		<Root>
			<ExecutorsFilterPanel
				filter={filters}
				language={lang}
				setFilter={setFilters}
				onSave={handleFilters}
				criteria={criteria}
			/>
		</Root>
	);
};

declare namespace Filter {
	interface Props {
		saveFilterOnRedux: (data: Orders.Chat.ModalFilter) => void;
		initFilter: Orders.Chat.BodyFilter;
	}
}

export default Filter;
